import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CFD, ETF, FX } from 'shared-modules/constants';
import { useGetBuilderServiceId } from 'shared-modules/services/hooks/builder';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import { CfdInstrumentIcon, CustomFlag, EtfInstrumentIcon } from '../../../../../../components';
import styles from './instrumentSelectionButton.module.scss';

export const InstrumentSelectionButton = ({ disabled }) => {
  const serviceId = useGetBuilderServiceId();
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const instrumentOptions = useApOrManualInstrumentOptions(false, serviceId);
  const memoizedOptions = useMemo(() => instrumentOptions, [instrumentOptions]);
  const label = memoizedOptions[serviceId]
    .map((instrument) => {
      const { label: instrumentLabel, value: instrumentValue } = instrument;
      if (instrumentValue === instrumentId) {
        const [firstInstrumentId, secondInstrumentId] = instrumentLabel?.split('/') || [];
        if (firstInstrumentId && secondInstrumentId) {
          return `${firstInstrumentId} / ${secondInstrumentId}`;
        }
        return instrumentLabel;
      }
      return null;
    })
    .filter(Boolean);

  const [firstFlag, secondFlag] = instrumentId?.split('/') || [];
  const content = () => {
    if (serviceId === FX) {
      return (
        <>
          <CustomFlag className={styles.icon} country={firstFlag} />
          <CustomFlag className={styles.icon} country={secondFlag} />
        </>
      );
    }
    if (serviceId === ETF) {
      return <EtfInstrumentIcon className={styles.singleIcon} instrumentId={instrumentId} size="medium" />;
    }
    if (serviceId === CFD) {
      return <CfdInstrumentIcon className={styles.singleIcon} instrumentId={instrumentId} size="medium" />;
    }
    return null;
  };

  return (
    <div className={styles.container} data-disabled={disabled}>
      <div className={styles.innerContainer}>
        {content()}
        <span className={styles.currency}>{label}</span>
      </div>
      <i className={`material-icons ${styles.arrow}`}>keyboard_arrow_right</i>
    </div>
  );
};

InstrumentSelectionButton.propTypes = {
  disabled: PropTypes.bool,
};

InstrumentSelectionButton.defaultProps = {
  disabled: false,
};
