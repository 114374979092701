import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../../components';
import { ReactComponent as NewsModeIcon } from '../../../../../assets/newsMode.svg';
import styles from './card.module.scss';

export const Card = memo(({ icon, alt, title, summary, detail, onClick, explanationOnClick }) => {
  return (
    <button className={styles.container} onClick={onClick} type="button">
      <div className={styles.innerContainer}>
        <div className={styles.mainContainer}>
          <div>
            <img src={icon} className={styles.icon} alt={alt} />
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.titleArea}>
              <div className={styles.title}>{title}</div>
            </div>
            <div className={styles.summaryArea}>
              <div className={styles.summary}>{summary}</div>
            </div>
          </div>
          <span className={styles.arrowContainer}>
            <i className={`material-icons ${styles.arrow}`}>keyboard_arrow_right</i>
          </span>
        </div>
        <div className={styles.subContainer}>
          <div className={styles.subInnerContainer}>
            <div className={styles.detailArea}>
              <div className={styles.detail}>{detail}</div>
            </div>
            <Button
              className={styles.button}
              onClick={(event) => {
                event.stopPropagation();
                explanationOnClick();
              }}
              width={56}
              secondary
            >
              <span className={styles.inline}>
                <NewsModeIcon className={styles.articleIcon} />
                解説
              </span>
            </Button>
          </div>
        </div>
      </div>
    </button>
  );
});

Card.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  explanationOnClick: PropTypes.func.isRequired,
};
