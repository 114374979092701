import React, { memo, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  BUY_SELL_MAIN,
  BUY_SELL_CHART_VALUE,
  COUNTRY_TYPE,
  NOT_PAIRED_YEN_PIP_MULTIPLIER,
  PAIRED_YEN_PIP_MULTIPLIER,
  FX,
} from 'shared-modules/constants';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { useInstrumentShortName } from 'shared-modules/hooks';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import { widget } from 'shared-modules/thirdPartyLibrary/TradingView/charting_library';
import {
  SELECTED_SIDE,
  chartType,
  isEmpty,
  PRICE_CHART_COLORS,
  basePriceLineProps,
  PRICE_AREA_OPTIONS,
  AREA_STYLE_OPTIONS,
  areaOptionsOco,
  DEFAULT_BACKGROUND_TRANSPARENCY,
} from 'shared-modules/services/builder';
import Logger from 'shared-modules/services/Logger';
import { changeChartResolution } from 'shared-modules/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { saveDefaultValuesFromLocalStorage } from 'shared-modules/services';
import { KEY_FOR_DEFAULT_CHART_RESOLUTION } from 'shared-modules/constants/chart';
import { changeChartSide } from 'shared-modules/redux/actions/manualTradeActions';
import { useEffectSetChildTabIndex } from '../../services/hooks';
import { Spin } from '../Spin';
import datafeed from '../Chart/datafeed';
import styles from './multiPriceChart.module.scss';
import { overrides as defaultOverrides } from '../Chart/Chart';

const createAskBidToggle = (title) => <div className="askbid-toggle">{title}</div>;

const MultiPriceChart = ({
  selectedCurrencyPairId,
  logicGroupsList,
  ocoIsChecked,
  orderSettingsList,
  serviceId,
  resolution: resolutionOuter,
  instrumentOptions,
  instrumentList,
  buySell,
  chartBuySell,
  basePrice,
  entryPriceValue,
  rangeSpreadPriceValue,
  isMobile,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const resolutionInner = useSelector((state) => state.chart.resolution);
  const resolution = useMemo(() => resolutionOuter || resolutionInner, [resolutionOuter, resolutionInner]);
  const chartSide = useSelector((state) => state.manualTrade.chartSide);

  const dropdownRef = useRef(null);
  const tvWidgetRef = useRef({});
  const priceAreaIdRef = useRef({});
  const basePriceLineRef = useRef({});
  const priceAreaRef = useRef({});
  const ocoAreaRef = useRef({});
  const ocoAreaIdRef = useRef({});
  const ocoAreaLineRef = useRef({});
  const priceLine1Ref = useRef({});
  const priceLine2Ref = useRef({});
  const logicGroupsListRef = useRef({});
  const chartContainerRef = useEffectSetChildTabIndex('-1');
  const prevSymbolRef = useRef(null);
  const [changingResolution, setChangingResolution] = useState(false);
  const changingResolutionRef = useRef(changingResolution);
  const prevChangingResolutionRef = useRef(changingResolution);
  const prevResolutionRef = useRef(null);
  const [finishedOnChartReady, setFinishedOnChartReady] = useState(false);
  const [changingSymbol, setChangingSymbol] = useState(false);
  const changingSymbolRef = useRef(changingSymbol);
  const prevChangingSymbolRef = useRef(changingSymbol);
  const finishedOnChartReadyRef = useRef(finishedOnChartReady);
  const prevFinishedOnChartReadyRef = useRef(finishedOnChartReady);
  const basePriceLineIdRef = useRef(null);

  const ocoPriceAreaValue = useMemo(
    () => 2 * entryPriceValue - rangeSpreadPriceValue,
    [entryPriceValue, rangeSpreadPriceValue],
  );

  const shortName = useInstrumentShortName(selectedCurrencyPairId);
  const symbol = useMemo(() => {
    return `${removeSuffix(shortName)}(${BUY_SELL_CHART_VALUE[chartSide]})`;
  }, [shortName, chartSide]);

  const color = useMemo(
    () => (chartBuySell === SELECTED_SIDE ? PRICE_CHART_COLORS.MAGENTA : PRICE_CHART_COLORS.SUMMER_SKY),
    [chartBuySell],
  );
  const areaOptions = useMemo(
    () => ({
      ...PRICE_AREA_OPTIONS,
      overrides: {
        ...AREA_STYLE_OPTIONS,
        backgroundColor: color,
        backgroundTransparency: DEFAULT_BACKGROUND_TRANSPARENCY,
      },
    }),
    [color],
  );

  const toggleChartSide = useCallback(
    (value) => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ side: value }));
      }
      dispatch(changeChartSide({ id: value }));
    },
    [dispatch],
  );

  const handleDropdownItemSelect = useCallback(
    (title, value) => {
      dropdownRef.current.applyOptions({
        title,
      });
      toggleChartSide(value);
    },
    [toggleChartSide],
  );

  const createDropdownItem = useCallback(
    (title, value) => ({
      title,
      onSelect: () => {
        handleDropdownItemSelect(title, value);
      },
    }),
    [handleDropdownItemSelect],
  );

  const dropdownOptionsRef = useRef(null);
  const dropdownOptions = useMemo(
    () => ({
      title: createAskBidToggle(Number(buySell) ? 'ASK' : 'BID'),
      items: [createDropdownItem(createAskBidToggle('BID'), 0), createDropdownItem(createAskBidToggle('ASK'), 1)],
    }),
    [createDropdownItem, buySell],
  );

  useEffect(() => {
    toggleChartSide(buySell);
  }, [buySell, toggleChartSide]);

  useEffect(() => {
    if (ALL_SERVICES.some((service) => (!instrumentOptions[service]).length) || !selectedCurrencyPairId) {
      return;
    }
    finishedOnChartReadyRef.current = false;
    setFinishedOnChartReady(false);

    const overrides = {
      ...defaultOverrides,
      'mainSeriesProperties.showPriceLine': false,
      'scalesProperties.showSeriesLastValue': false,
    };
    const widgetOptions = {
      symbol,
      datafeed: datafeed({ instrumentOptions, isMobile, serviceId, instrumentList }),
      interval: resolution,
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      custom_css_url: isMobile ? 'mobileCustomStyle.css' : 'customStyle.css',
      timezone: 'Asia/Tokyo',
      locale: 'ja',
      disabled_features: [
        'display_market_status',
        'items_favoriting',
        'header_screenshot',
        'header_saveload',
        'header_undo_redo',
        'header_fullscreen_button',
        'header_compare',
        'header_indicators',
        'header_chart_type',
        !isMobile && 'header_resolutions',
        'timeframes_toolbar',
        'study_dialog_search_control',
        !isMobile && 'header_widget',
        'create_volume_indicator_by_default',
        'left_toolbar',
        'header_symbol_search',
        'header_settings',
        'symbol_search_hot_key',
      ],
      autosize: true,
      theme: 'Dark',
      overrides,
      settings_overrides: overrides,
    };
    finishedOnChartReadyRef.current = false;
    setFinishedOnChartReady(false);
    tvWidgetRef.current = new widget(widgetOptions); // eslint-disable-line
    tvWidgetRef.current.onChartReady(() => {
      setLoading(false);
    });
    tvWidgetRef.current.onChartReady(() => {
      // underDisposalRef.current = false;
      finishedOnChartReadyRef.current = true;
      // これをトリガーとして chart object の描画が行われる
      setFinishedOnChartReady(true);
    });

    if (isMobile) {
      tvWidgetRef.current.headerReady().then(() => {
        const addDropDown = async () => {
          dropdownRef.current = await tvWidgetRef.current.createDropdown(dropdownOptionsRef.current);
        };

        addDropDown();
      });
    }

    tvWidgetRef.current.subscribe('time_interval', (e) => {
      const newResolution = e.label;
      // ここが resolution 変更の起点となる
      if (newResolution !== prevResolutionRef.current) {
        changingResolutionRef.current = true;
        setChangingResolution(true);
      }
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ resolution: newResolution }));
      }
      dispatch(changeChartResolution({ resolution: newResolution }));
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_CHART_RESOLUTION,
        value: newResolution,
      });
    });
  }, [
    chartContainerRef,
    instrumentOptions,
    selectedCurrencyPairId,
    instrumentList,
    serviceId,
    resolution,
    isMobile,
    symbol,
    dispatch,
  ]);

  useEffect(() => {
    dropdownRef.current?.applyOptions(dropdownOptions);
    dropdownOptionsRef.current = dropdownOptions;
  }, [dropdownOptions]);

  const renderLines = useCallback(() => {
    if (changingSymbolRef.current || changingSymbol) {
      // symbol 変更中の場合は symbol 変更後に renderLines が再度呼び出されるためスキップ
      return;
    }
    if (changingResolutionRef.current || changingResolution) {
      // resolution 変更中の場合は resolution 変更後に renderLines が再度呼び出されるためスキップ
      return;
    }

    if (!isEmpty(priceLine1Ref.current)) {
      priceLine1Ref.current.remove();
    }
    if (!isEmpty(priceLine2Ref.current)) {
      priceLine2Ref.current.remove();
    }
    if (!isEmpty(ocoAreaIdRef.current)) {
      tvWidgetRef.current.activeChart().removeEntity(ocoAreaIdRef.current);
      ocoAreaIdRef.current = {};
    }
    if (!isEmpty(ocoAreaLineRef.current) && !isEmpty(ocoAreaRef.current)) {
      ocoAreaLineRef.current.remove();
    }

    priceLine1Ref.current = tvWidgetRef.current
      .activeChart()
      .createOrderLine()
      .setExtendLeft(true)
      .setLineLength(1)
      .setText('')
      .setQuantity('')
      .setPrice(entryPriceValue)
      .setLineStyle(2);
    priceLine2Ref.current = tvWidgetRef.current
      .activeChart()
      .createOrderLine()
      .setExtendLeft(true)
      .setLineLength(1)
      .setText('')
      .setQuantity('')
      .setPrice(rangeSpreadPriceValue)
      .setLineStyle(2);

    if (ocoIsChecked) {
      ocoAreaIdRef.current = tvWidgetRef.current
        .activeChart()
        .createMultipointShape([{ price: entryPriceValue }, { price: ocoPriceAreaValue }], areaOptionsOco);
      ocoAreaRef.current = tvWidgetRef.current.activeChart().getShapeById(ocoAreaIdRef.current);
      ocoAreaRef.current.bringToFront();
      ocoAreaLineRef.current = tvWidgetRef.current
        .activeChart()
        .createOrderLine()
        .setExtendLeft(true)
        .setLineLength(1)
        .setText('')
        .setQuantity('')
        .setPrice(ocoPriceAreaValue)
        .setLineStyle(2);
      ocoAreaLineRef.current
        .setLineColor(PRICE_CHART_COLORS.YELLOW)
        .setBodyBorderColor(PRICE_CHART_COLORS.YELLOW)
        .setBodyTextColor(PRICE_CHART_COLORS.YELLOW)
        .setQuantityBorderColor(PRICE_CHART_COLORS.YELLOW)
        .setQuantityTextColor(PRICE_CHART_COLORS.YELLOW);
    } else {
      if (!isEmpty(priceLine1Ref.current)) {
        priceLine1Ref.current
          .setLineColor(color)
          .setBodyBorderColor(color)
          .setBodyTextColor(color)
          .setQuantityBorderColor(color)
          .setQuantityTextColor(color);
      }

      ocoAreaRef.current = {};
    }

    try {
      if (!isEmpty(priceLine1Ref.current) || !isEmpty(ocoAreaRef.current)) {
        if (ocoIsChecked) {
          priceLine1Ref.current
            .setLineColor(PRICE_CHART_COLORS.YELLOW)
            .setBodyBorderColor(PRICE_CHART_COLORS.YELLOW)
            .setBodyTextColor(PRICE_CHART_COLORS.YELLOW)
            .setQuantityBorderColor(PRICE_CHART_COLORS.YELLOW)
            .setQuantityTextColor(PRICE_CHART_COLORS.YELLOW);
        } else {
          priceLine1Ref.current
            .setLineColor(color)
            .setBodyBorderColor(color)
            .setBodyTextColor(color)
            .setQuantityBorderColor(color)
            .setQuantityTextColor(color);
        }
      }

      if (!isEmpty(priceLine2Ref.current)) {
        priceLine2Ref.current
          .setLineColor(color)
          .setBodyBorderColor(color)
          .setBodyTextColor(color)
          .setQuantityBorderColor(color)
          .setQuantityTextColor(color);
      }
    } catch (error) {
      Logger.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changingResolution, changingSymbol, color, ocoIsChecked, entryPriceValue, rangeSpreadPriceValue]);

  const renderBasePrice = useCallback(() => {
    if (isEmpty(basePriceLineRef.current)) {
      basePriceLineIdRef.current = tvWidgetRef.current
        .activeChart()
        .createShape(
          { price: basePrice },
          { shape: 'horizontal_line', lock: true, disableSelection: true, overrides: basePriceLineProps },
        );
    }

    if (basePriceLineIdRef.current) {
      basePriceLineRef.current = tvWidgetRef.current.activeChart().getShapeById(basePriceLineIdRef.current);
      basePriceLineRef.current.sendToBack();
      basePriceLineRef.current.setPoints([{ price: basePrice }]);
    }
  }, [basePrice]);

  const renderShapes = useCallback(() => {
    if (changingSymbolRef.current || changingSymbol) {
      // symbol 変更中の場合は symbol 変更後に renderShapes が再度呼び出されるためスキップ
      return;
    }
    if (changingResolutionRef.current || changingResolution) {
      // resolution 変更中の場合は resolution 変更後に renderShapes が再度呼び出されるためスキップ
      return;
    }
    if (isEmpty(priceAreaIdRef.current)) {
      priceAreaIdRef.current = tvWidgetRef.current
        .activeChart()
        .createMultipointShape([{ price: entryPriceValue }, { price: rangeSpreadPriceValue }], areaOptions);
    }
    priceAreaRef.current = tvWidgetRef.current.activeChart().getShapeById(priceAreaIdRef.current);
    priceAreaRef.current.bringToFront();

    if (!isEmpty(priceLine1Ref.current) && !isEmpty(priceLine2Ref.current) && !isEmpty(priceAreaRef.current)) {
      priceLine1Ref.current.setPrice(entryPriceValue);
      priceLine2Ref.current.setPrice(rangeSpreadPriceValue);
      priceAreaRef.current.setPoints([{ price: entryPriceValue }, { price: rangeSpreadPriceValue }]);
    }
  }, [areaOptions, changingResolution, changingSymbol, entryPriceValue, rangeSpreadPriceValue]);

  useEffect(() => {
    return () => {
      if (tvWidgetRef.current.remove) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = {};
      }
    };
  }, []);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    const changeChartProps = () => {
      const callbacks = [];
      if (symbol !== prevSymbolRef.current) {
        changingSymbolRef.current = true;
        if (!isEmpty(basePriceLineRef.current)) {
          tvWidgetRef.current.activeChart().removeEntity(basePriceLineRef.current);
          tvWidgetRef.current.activeChart().removeEntity(basePriceLineIdRef.current);
          basePriceLineRef.current = {};
          basePriceLineIdRef.current = {};
        }
        if (!isEmpty(priceAreaIdRef.current)) {
          tvWidgetRef.current.activeChart().removeEntity(priceAreaIdRef.current);
          if (priceAreaIdRef.current) {
            priceAreaIdRef.current = {};
          }
          if (priceAreaRef.current) {
            priceAreaRef.current = {};
          }
        }
        if (
          ocoIsChecked &&
          !isEmpty(ocoAreaIdRef.current) &&
          !isEmpty(ocoAreaLineRef.current) &&
          !isEmpty(ocoAreaRef.current)
        ) {
          tvWidgetRef.current.activeChart().removeEntity(ocoAreaIdRef.current);
          ocoAreaLineRef.current.remove();
          ocoAreaIdRef.current = {};
          ocoAreaRef.current = {};
        }
        setChangingSymbol(true); // symbol 変更開始
        callbacks.push(() => {
          prevSymbolRef.current = symbol;
          changingSymbolRef.current = false;
          // これをトリガーとして chart object の描画が行われる
          setChangingSymbol(false); // symbol 変更終了
        });
      }
      if (resolution !== prevResolutionRef.current) {
        callbacks.push(() => {
          prevResolutionRef.current = resolution;
          changingResolutionRef.current = false;
          // これをトリガーとして chart object の描画が行われる
          setChangingResolution(false); // resolution 変更終了
        });
      }
      tvWidgetRef.current.setSymbol(symbol, resolution, () => callbacks.forEach((cb) => cb()));
    };
    if (finishedOnChartReadyRef.current) {
      changeChartProps();
    } else {
      tvWidgetRef.current.onChartReady(changeChartProps);
    }
  }, [resolution, isMobile, symbol, areaOptions.overrides, ocoIsChecked]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }

    tvWidgetRef.current.onChartReady(() => {
      if (!isEmpty(priceAreaRef.current)) {
        priceAreaRef.current.setProperties(areaOptions.overrides);
      }
    });
  });

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    if (changingSymbolRef.current || changingSymbol) {
      // symbol 変更中の場合は symbol 変更後に renderLines が再度呼び出されるためスキップ
      return;
    }
    if (changingResolutionRef.current || changingResolution) {
      // resolution 変更中の場合は resolution 変更後に renderLines が再度呼び出されるためスキップ
      return;
    }
    tvWidgetRef.current.onChartReady(() => {
      if (logicGroupsListRef.current.length) {
        logicGroupsListRef.current.forEach((areaShapes) => {
          areaShapes.forEach((shape) => {
            if (shape.remove) {
              shape.remove();
            } else {
              tvWidgetRef.current.activeChart().removeEntity(shape);
            }
          });
        });
      }

      logicGroupsListRef.current = logicGroupsList.map((group) => {
        const entryPrice = orderSettingsList.find((el) => el.logicGroupId === group.id).entryPrice1;
        const rangeSpreadGroup = group.rangeSpread;
        let multiplier;
        // TODO CFD FXかそうでないかの判定で問題ないか要確認
        if (serviceId === FX) {
          multiplier = group.instrumentId.endsWith(COUNTRY_TYPE.JPY)
            ? PAIRED_YEN_PIP_MULTIPLIER
            : NOT_PAIRED_YEN_PIP_MULTIPLIER;
        } else {
          multiplier = 1;
        }
        const isBuyGroup = Number(group.buySell) === BUY_SELL_MAIN.BUY.ID;
        const rangeSpreadBuyGroupValue = entryPrice - rangeSpreadGroup * multiplier;
        const rangeSpreadSellGroupValue = entryPrice + rangeSpreadGroup * multiplier;
        const rangeSpreadPrice = isBuyGroup ? rangeSpreadBuyGroupValue : rangeSpreadSellGroupValue;
        const ocoPrice = 2 * entryPrice - rangeSpreadPrice;
        const colorGroup = isBuyGroup ? PRICE_CHART_COLORS.MAGENTA : PRICE_CHART_COLORS.SUMMER_SKY;
        const priceAreaId = tvWidgetRef.current
          .activeChart()
          .createMultipointShape([{ price: entryPrice }, { price: rangeSpreadPrice }], {
            ...PRICE_AREA_OPTIONS,
            overrides: {
              ...AREA_STYLE_OPTIONS,
              backgroundColor: colorGroup,
              backgroundTransparency: DEFAULT_BACKGROUND_TRANSPARENCY,
            },
          });

        const priceArea = tvWidgetRef.current.activeChart().getShapeById(priceAreaId);
        priceArea.bringToFront();

        let ocoPriceArea = {};
        let ocoPriceLine = {};
        if (group.ocoIsChecked) {
          ocoPriceArea = tvWidgetRef.current
            .activeChart()
            .createMultipointShape([{ price: entryPrice }, { price: ocoPrice }], areaOptionsOco);

          ocoPriceLine = tvWidgetRef.current
            .activeChart()
            .createOrderLine()
            .setExtendLeft(true)
            .setLineLength(1)
            .setText('')
            .setQuantity('')
            .setPrice(ocoPrice)
            .setLineStyle(2)
            .setLineColor(PRICE_CHART_COLORS.YELLOW)
            .setBodyBorderColor(PRICE_CHART_COLORS.YELLOW)
            .setBodyTextColor(PRICE_CHART_COLORS.YELLOW)
            .setQuantityBorderColor(PRICE_CHART_COLORS.YELLOW)
            .setQuantityTextColor(PRICE_CHART_COLORS.YELLOW);
        }

        const priceLine1 = tvWidgetRef.current
          .activeChart()
          .createOrderLine()
          .setExtendLeft(true)
          .setLineLength(1)
          .setText('')
          .setQuantity('')
          .setPrice(entryPrice)
          .setLineStyle(2)
          .setLineColor(group.ocoIsChecked ? PRICE_CHART_COLORS.YELLOW : colorGroup)
          .setBodyBorderColor(group.ocoIsChecked ? PRICE_CHART_COLORS.YELLOW : colorGroup)
          .setBodyTextColor(group.ocoIsChecked ? PRICE_CHART_COLORS.YELLOW : colorGroup)
          .setQuantityBorderColor(group.ocoIsChecked ? PRICE_CHART_COLORS.YELLOW : colorGroup)
          .setQuantityTextColor(group.ocoIsChecked ? PRICE_CHART_COLORS.YELLOW : colorGroup);

        const priceLine2 = tvWidgetRef.current
          .activeChart()
          .createOrderLine()
          .setExtendLeft(true)
          .setLineLength(1)
          .setText('')
          .setQuantity('')
          .setPrice(rangeSpreadPrice)
          .setLineStyle(2)
          .setLineColor(colorGroup)
          .setBodyBorderColor(colorGroup)
          .setBodyTextColor(colorGroup)
          .setQuantityBorderColor(colorGroup)
          .setQuantityTextColor(colorGroup);

        return [priceAreaId, priceLine1, priceLine2, ocoPriceArea, ocoPriceLine];
      });

      if (isEmpty(priceAreaRef.current)) {
        priceAreaIdRef.current = tvWidgetRef.current
          .activeChart()
          .createMultipointShape([{ price: entryPriceValue }, { price: rangeSpreadPriceValue }], areaOptions);
        priceAreaRef.current = tvWidgetRef.current.activeChart().getShapeById(priceAreaIdRef.current);
        priceAreaRef.current.bringToFront();

        priceLine1Ref.current = tvWidgetRef.current
          .activeChart()
          .createOrderLine()
          .setExtendLeft(true)
          .setLineLength(1)
          .setText('')
          .setQuantity('')
          .setPrice(entryPriceValue)
          .setLineStyle(2);

        priceLine2Ref.current = tvWidgetRef.current
          .activeChart()
          .createOrderLine()
          .setExtendLeft(true)
          .setLineLength(1)
          .setText('')
          .setQuantity('')
          .setPrice(rangeSpreadPriceValue)
          .setLineStyle(2);
      }
    });
    // eslint-disable-next-line
  }, [logicGroupsList, serviceId]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    tvWidgetRef.current.onChartReady(() => {
      tvWidgetRef.current.chart().setChartType(chartType);
    });
  }, []);

  // チャートオブジェクトに関連するプロパティの変更をトリガーに描画を試みる
  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    if (finishedOnChartReadyRef.current) {
      renderLines();
      renderShapes();
    } else {
      tvWidgetRef.current.onChartReady(() => renderLines());
    }
  }, [renderLines, renderShapes]);

  // チャート準備完了時に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (finishedOnChartReady !== prevFinishedOnChartReadyRef.current) {
      prevFinishedOnChartReadyRef.current = finishedOnChartReady;
      if (finishedOnChartReady) {
        renderBasePrice();
        renderLines();
        renderShapes();
      }
    }
  }, [finishedOnChartReady, renderBasePrice, renderLines, renderShapes]);

  // シンボル変更後に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (changingSymbol !== prevChangingSymbolRef.current) {
      prevChangingSymbolRef.current = changingSymbol;
      if (!changingSymbol) {
        renderBasePrice();
        renderShapes();
        renderLines();
      }
    }
  }, [changingSymbol, renderBasePrice, renderLines, renderShapes]);

  // 足種変更後に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (changingResolution !== prevChangingResolutionRef.current) {
      prevChangingResolutionRef.current = changingResolution;
      if (!changingResolution) {
        renderLines();
        renderShapes();
      }
    }
  }, [changingResolution, renderLines, renderShapes]);

  return (
    <div className={classNames(styles.wrapper, { [styles.loading]: loading })}>
      {loading && <Spin className={styles.loader} />}
      <div className={classNames(styles.chartAreaWrapper, { [styles.displayNone]: loading })}>
        <div className={styles.chartContainer} ref={chartContainerRef} />
      </div>
    </div>
  );
};
MultiPriceChart.propTypes = {
  selectedCurrencyPairId: PropTypes.string.isRequired,
  logicGroupsList: PropTypes.arrayOf(PropTypes.shape({})),
  orderSettingsList: PropTypes.arrayOf(PropTypes.shape({})),
  instrumentList: PropTypes.shape({}).isRequired,
  instrumentOptions: PropTypes.shape(
    ALL_SERVICES.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      {},
    ),
  ).isRequired,
  serviceId: PropTypes.string.isRequired,
  resolution: PropTypes.string,
  ocoIsChecked: PropTypes.bool.isRequired,
  basePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  buySell: PropTypes.number.isRequired,
  chartBuySell: PropTypes.number,
  entryPriceValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  rangeSpreadPriceValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isMobile: PropTypes.bool,
};
MultiPriceChart.defaultProps = {
  orderSettingsList: [],
  logicGroupsList: [],
  resolution: '',
  chartBuySell: 1,
  isMobile: false,
};

export default memo(MultiPriceChart);
