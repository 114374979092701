import React, { memo, useCallback, useState } from 'react';
import ReactSwitch from 'react-switch';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './switch.module.scss';

const height = 31;
const handleDiameter = height - 4;

export const Switch = memo(({ checked, disabled, onColor, onChange }) => {
  const [focused, setFocused] = useState(false);
  const onKeyDown = useCallback(() => onChange(!checked), [onChange, checked]);
  const handleKeyDown = useKeyDownHandler(onKeyDown);
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  return (
    <ReactSwitch
      className={classNames(styles.switch, { [styles.focused]: focused })}
      width={51}
      height={height}
      checked={checked}
      disabled={disabled}
      onColor={onColor}
      offColor="#636363"
      onHandleColor="#d9d9d9"
      offHandleColor="#d9d9d9"
      activeBoxShadow=""
      checkedIcon={false}
      uncheckedIcon={false}
      handleDiameter={handleDiameter}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      onFocusCapture={handleFocus}
      onBlurCapture={handleBlur}
    />
  );
});

Switch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
  disabled: false,
  onColor: '#F45266',
};
