import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { BUY_SELL_MAIN, INDICATORS, TRADE_METHODS } from 'shared-modules/constants';
import { getServiceQuantityUnit, toCommaSeparatedNumber } from 'shared-modules/services';
import {
  useCalcPriceLimit,
  useCheckOnNegativePriceWhenSimulate,
  useGetBuilderOrderRequestData,
  useGetBuilderServiceId,
} from 'shared-modules/services/hooks/builder';
import { getPipsLabelWithParentheses } from 'shared-modules/utils';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import { useComprehensiveEvaluationTargetTermIds } from 'shared-modules/hooks/comprehensiveEvaluation';
import { editBuilderLogicGroup } from 'shared-modules/redux/actions/builderActions';
import { useInstrumentSettings } from 'shared-modules/services/hooks';
import {
  deleteBuilderLogicGroupById,
  getBuilderSimulationDataRequest,
  getSilentMultiBuilderSimulationDataRequest,
} from '../../../../../redux/actions';
import { Table } from '../../../../../components';
import { Empty } from './Empty';
import { SeqCell, BuySellCell, NumberCell as Cell } from './Cell';
import { DeleteIcon } from './DeleteIcon';
import { EditIcon } from './EditIcon';
import styles from './chartSettings.module.scss';

const builderResized = {
  key: 'builderLogicGroupTable',
  default: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
};

const techResized = {
  key: 'techLogicGroupTable',
  default: [75, 60, 37, 120, 120, 30, 30, 60, 60, 60, 60, 75, 30],
};

// レンジセルのコンポーネント
const RangeCell = ({ row }) => {
  const {
    original: { buySell, entryPriceValue, rangeSpread, realInstrumentId },
  } = row;
  const isBuy = buySell === BUY_SELL_MAIN.BUY.ID;
  const priceLimit = useCalcPriceLimit(isBuy, Number(entryPriceValue), Number(rangeSpread), realInstrumentId);
  const { pricePrecision: initialPrecision = 0.01 } = useInstrumentSettings(realInstrumentId, TRADE_METHODS.AP.ID);
  const pricePrecision = useMemo(() => {
    const logResult = Math.log10(initialPrecision);

    return (logResult === 0 ? 0 : -logResult) ?? 0;
  }, [initialPrecision]);
  const formatValue = (value) => {
    return Number(value).toFixed(pricePrecision);
  };

  return (
    <div>
      {isBuy ? formatValue(priceLimit) : formatValue(entryPriceValue)} 〜{' '}
      {isBuy ? formatValue(entryPriceValue) : formatValue(priceLimit)}
    </div>
  );
};

RangeCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      buySell: PropTypes.oneOf(Object.values(BUY_SELL_MAIN).map((item) => item.ID)).isRequired,
      entryPriceValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      rangeSpread: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      realInstrumentId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const LogicGroupTable = memo(({ tableType }) => {
  const dispatch = useDispatch();
  const serviceId = useGetBuilderServiceId();
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const pipsLabelWithParentheses = getPipsLabelWithParentheses(serviceId, instrumentId);
  const tableData = useSelector((state) => state.builder.logicGroupsList);

  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);
  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const termIdsForAssessment = useComprehensiveEvaluationTargetTermIds({ excludeTermId: defaultSelectionTermId });
  const getOrderRequestData = useGetBuilderOrderRequestData();
  const checkOnNegativePrice = useCheckOnNegativePriceWhenSimulate();

  /** シミューレーション再実行処理. */
  const getSimulationData = useCallback(() => {
    const successCallback = () => {
      const orderRequestData = getOrderRequestData();
      dispatch(
        getSilentMultiBuilderSimulationDataRequest({
          orderRequestData,
          termIdList: termIdsForAssessment,
        }),
      );
      dispatch(
        getBuilderSimulationDataRequest({
          orderRequestData,
          termId: defaultSelectionTermId,
        }),
      );
    };
    checkOnNegativePrice(successCallback);
  }, [checkOnNegativePrice, getOrderRequestData, dispatch, termIdsForAssessment, defaultSelectionTermId]);

  /** ルール(ロジック)編集. */
  const handleEdit = useCallback(
    (id) => {
      dispatch(editBuilderLogicGroup({ id }));
    },
    [dispatch],
  );

  /** ルール(ロジック)削除. */
  const handleDelete = useCallback(
    (id) => {
      // ルール削除
      dispatch(deleteBuilderLogicGroupById({ id }));
      // シミューレーション選択時にルールを削除した場合、シミュレーションの再実行を実施
      if (!allowOrderEdit) {
        getSimulationData();
      }
    },
    [dispatch, allowOrderEdit, getSimulationData],
  );

  const builderColumns = useMemo(
    () => [
      {
        Header: '番号',
        accessor: 'number',
        disableSortBy: true,
        Cell: SeqCell,
        minWidth: 42,
      },
      {
        Header: '銘柄',
        accessor: 'instrumentId',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => removeSuffix(value),
        minWidth: 115,
      },
      {
        Header: '売買',
        accessor: 'buySell',
        Cell: BuySellCell,
        disableSortBy: true,
        maxWidth: 42,
      },
      { Header: `数量(${quantityUnit})`, accessor: 'amount', isNumber: true, disableSortBy: true, minWidth: 64 },
      { Header: '本数', accessor: 'itemsCount', isNumber: true, disableSortBy: true, maxWidth: 42 },
      {
        Header: 'レンジ',
        accessor: 'range',
        Cell: RangeCell,
        isNumber: true,
        disableSortBy: true,
        minWidth: 170,
      },
      { Header: 'レンジ幅', accessor: 'rangeSpread', Cell, isNumber: true, disableSortBy: true, minWidth: 65 },
      {
        Header: 'OCO',
        accessor: 'ocoIsChecked',
        Cell: ({ cell: { value } }) => (value ? 'ON' : 'OFF'),
        disableSortBy: true,
        minWidth: 48,
      },
      {
        Header: `利確幅${pipsLabelWithParentheses}`,
        accessor: 'profitMargin',
        Cell,
        isNumber: true,
        disableSortBy: true,
        minWidth: 92,
      },
      {
        Header: `損切幅${pipsLabelWithParentheses}`,
        accessor: 'stopLossRange',
        Cell,
        isNumber: true,
        disableSortBy: true,
        minWidth: 92,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        Cell,
        isNumber: true,
        disableSortBy: true,
        minWidth: 78,
      },
      {
        Header: 'カウンター値',
        accessor: 'counter',
        Cell: ({
          row: {
            original: { counter, counterIsFixed },
          },
        }) => {
          const separatedValue = toCommaSeparatedNumber(counter);
          const counterValue = counterIsFixed ? `${separatedValue}固定` : separatedValue;
          return counter ? counterValue : '-';
        },
        isNumber: true,
        disableSortBy: true,
        minWidth: 90,
      },
      { Header: '編集', accessor: '', Cell, isNumber: true, disableSortBy: true, minWidth: 46 },
      {
        Header: '編集',
        accessor: 'edit',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { id },
          },
        }) => <EditIcon id={id} onClick={handleEdit} />,
        disableSortBy: true,
        sticky: 'right',
      },
      {
        Header: '削除',
        accessor: 'options',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { id },
          },
        }) => <DeleteIcon id={id} onClick={handleDelete} />,
        disableSortBy: true,
        sticky: 'right',
        minWidth: 46,
      },
    ],
    [handleEdit, handleDelete, pipsLabelWithParentheses, quantityUnit],
  );

  const techColumns = useMemo(
    () => [
      {
        Header: '銘柄',
        accessor: 'instrumentId',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => removeSuffix(value),
      },
      {
        Header: '売買シグナル',
        accessor: 'buySell',
        Cell: BuySellCell,
        disableSortBy: true,
      },
      {
        Header: '全決済',
        accessor: 'isAllSettlement',
        Cell: ({
          row: {
            original: { isAllSettlement },
          },
        }) => {
          return isAllSettlement ? 'ON' : 'OFF';
        },
      },
      {
        Header: 'インジケーター1',
        accessor: 'indicator1',
        Cell: ({
          row: {
            original: { indicator1 },
          },
        }) => {
          return INDICATORS.find((i1) => i1.value === indicator1)?.label;
        },
      },
      {
        Header: 'インジケーター2',
        accessor: 'indicator2',
        Cell: ({
          row: {
            original: { indicator2 },
          },
        }) => {
          return INDICATORS.find((i2) => i2.value === indicator2)?.label ?? '-';
        },
      },
      { Header: `数量(${quantityUnit})`, accessor: 'amount', isNumber: true, disableSortBy: true },
      { Header: '本数', accessor: 'itemsCount', isNumber: true, disableSortBy: true },
      { Header: 'レンジ幅', accessor: 'rangeSpread', Cell, isNumber: true, disableSortBy: true },
      {
        Header: `利確幅${pipsLabelWithParentheses}`,
        accessor: 'profitMargin',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: `損切幅${pipsLabelWithParentheses}`,
        accessor: 'stopLossRange',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: 'カウンター値',
        accessor: 'counter',
        Cell: ({
          row: {
            original: { counter, counterIsFixed },
          },
        }) => {
          const separatedValue = toCommaSeparatedNumber(counter);
          const counterValue = counterIsFixed ? `${separatedValue}固定` : separatedValue;
          return counter ? counterValue : '-';
        },
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'options',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { id },
          },
        }) => allowOrderEdit && <DeleteIcon id={id} onClick={handleDelete} />,
        disableSortBy: true,
        sticky: 'right',
      },
    ],
    [handleDelete, pipsLabelWithParentheses, quantityUnit, allowOrderEdit],
  );

  const columns = useMemo(() => {
    switch (tableType) {
      case 'builder':
        return builderColumns;
      case 'tech':
        return techColumns;
      default:
        return [];
    }
  }, [builderColumns, techColumns, tableType]);

  const resized = useMemo(() => {
    switch (tableType) {
      case 'builder':
        return builderResized;
      case 'tech':
        return techResized;
      default:
        return [];
    }
  }, [tableType]);

  if (!tableData.length) {
    return (
      <Empty
        title="追加されたルールはありません。"
        desc={`${tableType === 'tech' ? '' : '複数の'}ルールを追加することができます。`}
      />
    );
  }
  return (
    <Table
      tableData={tableData}
      columns={columns}
      resized={resized}
      key={1}
      rowHight={35}
      headerClassName={styles.tableHeader}
      rowClassName={styles.tableRow}
    />
  );
});

LogicGroupTable.propTypes = {
  tableType: PropTypes.string,
};

LogicGroupTable.defaultProps = {
  tableType: 'builder',
};
