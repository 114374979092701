import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBuilderChartDataRequest } from '../../../../redux/actions';
import { SplitPane } from '../../../../components';
import { SideMenu } from './SideMenu';
import { SimulatingChart } from './SimulatingChart';
import { ChartSettings } from './ChartSettings';
import { Footer } from './Footer';
import styles from './configurationPage.module.scss';
import { InstrumentSelection } from './InstrumentSelection';

export const ConfigurationPage = memo(() => {
  const dispatch = useDispatch();
  const [issueIsHovered, setIssueIsHovered] = useState(false);

  useEffect(() => {
    dispatch(getBuilderChartDataRequest());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      {issueIsHovered && <InstrumentSelection setIssueIsHovered={setIssueIsHovered} />}
      <SideMenu issueIsHovered={issueIsHovered} setIssueIsHovered={setIssueIsHovered} />
      <div className={styles.detail}>
        <div className={styles.content}>
          <SplitPane split="horizontal" defaultSize="60%" minSize={200}>
            <SimulatingChart />
            <ChartSettings />
          </SplitPane>
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
});
