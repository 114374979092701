/* eslint-disable-next-line import/no-unresolved */
import { useMemo } from 'react';
import { CFD, ETF, FX } from '../constants';
import { useServiceName } from '.';
import { getServiceQuantityUnit } from '../services';
import { useGetBuilderServiceId } from '../services/hooks/builder';

const getQuantityTooltip = (serviceId) => {
  if (serviceId === FX) {
    return '各リピード注文ごとの注文数量を指定します。<br>1＝1万通貨<br>ビルダーでは、最小0.1（万）＝1,000通貨単位の取引が可能です。<br>※南アランド/円のみ10,000通貨単位';
  }
  if (serviceId === ETF) {
    return '各リピード注文ごとの注文数量を指定します。<br>ビルダーでは、最小1口単位の取引が可能です。<br>※TOPIX連動ETFのみ10口単位';
  }
  if (serviceId === CFD) {
    return '各リピード注文ごとの注文数量を指定します。<br>ビルダーでは、最小0.1Lotの取引が可能です。';
  }
  return null;
};

export const getEntryPricePipsValueMessage = (serviceId) => {
  if (serviceId === FX) {
    return '値幅はpipsで指定します。';
  }
  if (serviceId === ETF) {
    return '値幅は円（日本銘柄）またはドル(米国銘柄）で指定します。';
  }
  if (serviceId === CFD) {
    return '';
  }
  return null;
};

export const useBuilderTooltips = (isTech) => {
  const imagePrefix = useMemo(() => (isTech ? 'tech' : 'multi'), [isTech]);
  const serviceId = useGetBuilderServiceId();
  const serviceName = useServiceName(serviceId);
  const isFX = serviceId === FX;
  const quantityUnit = getServiceQuantityUnit(serviceId);
  return {
    assets: {
      label: '銘柄',
    },
    buySell: {
      label: '売買',
      tooltipMessage:
        'リピート注文の新規取引の方向を選びます。<br>買：価格の上昇で利益を得ます（新規買い → 決済売り）<br>売：価格の下落で利益を得ます（新規売り → 決済買い）',
      descriptionImage: `multi${serviceName}_buySell.png`,
    },
    rangeSpread: {
      label: isFX ? '値幅(pips)' : '値幅',
      tooltipMessage: isFX
        ? '複数のリピート注文を設定するレンジの値幅を指定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '複数の注文を設定するレンジの幅を指定します。',
      descriptionImage: `${imagePrefix}${serviceName}_rangeSpread.png`,
    },
    itemsCount: {
      label: '本数',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? '指定したレンジの値幅の中に何本のリピート注文を設定するかを決めます。注文は等間隔に設定されます。<br>注文間隔（pips）＝値幅÷（本数-1）<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '指定したレンジの値幅の中に何本のリピート注文を設定するかを決めます。注文は等間隔に設定されます。<br>注文間隔＝値幅÷（本数-1）',
      descriptionImage: `${imagePrefix}${serviceName}_items_count.png`,
    },
    quantity: {
      label: `数量(${quantityUnit})`,
      tooltipMessage: getQuantityTooltip(serviceId),
      descriptionImage: `multi${serviceName}_quantity.png`,
    },
    entryPricePipsValue: {
      label: 'レンジ',
      // eslint-disable-next-line max-len, prettier/prettier
      tooltipMessage: `複数のリピート注文を設定するレンジの開始位置を指定します。<br>【買いの場合】<br>レンジの上限値を指定します。「値幅」分買い下がるように、指定した本数の買い注文が設定されます。<br>【売りの場合】<br>レンジの下限値を指定します。「値幅」分売り上がるように、指定した本数の売り注文が設定されます。`,
      descriptionImage: `multi${serviceName}_entry.png`,
    },
    range: {
      label: 'レンジ',
    },
    ocoCheckbox: {
      label: 'OCO設定',
      // eslint-disable-next-line max-len, prettier/prettier
      // eslint-disable-next-line max-len
      tooltipMessage:
        'ONに設定した場合は、レンジの上限値（買いの場合）または下限値（売りの場合）を挟んで、指定した値幅の2倍の範囲に初回注文をOCOで設定します。<br>OCO注文とは、1つの注文で2つの価格を同時に発注する手法です。どちらかが成立すると、もう一方は自動的にキャンセルになります。',
      descriptionImage: `multi${serviceName}_entry_oco.png`,
    },
    profitMargin: {
      label: isFX ? '利確幅(pips)' : '利確幅',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? '保有中の建玉が買った（売った）価格から利益の方向にどのくらい相場が動いたら利益確定（利確）するかを値幅（pips）で指定します。'
        : '保有中の建玉が買った（売った）価格から利益の方向にどのくらい相場が動いたら利益確定（利確）するかを値幅で指定します。',
      descriptionImage: `multi${serviceName}_profitMargin.png`,
    },
    stopLossCheckbox: {
      label: '損切幅',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? '保有中の建玉が買った（売った）価格からどのくらい損失の方向に相場が動いたら損失確定（損切り）するかを値幅（pips）で指定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '保有中の建玉が買った（売った）価格からどのくらい損失の方向に相場が動いたら損失確定（損切り）するかを値幅で指定します。',
      descriptionImage: `multi${serviceName}_stopLoss.png`,
    },
    followCheckbox: {
      label: isFX ? 'フォロー値(pips)' : 'フォロー値',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? '値幅に基づいて、2回目以降の新規注文における順張り方向のエントリー条件を設定します。<br><br>【買いの場合】<br>決済価格からどのくらい上がったら、次新たに買うかを設定する、再エントリーの条件になります。<br>【売りの場合】<br>決済価格からどれくらい下がったら、次新たに売るかを設定する、再エントリーの条件になります。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）' // eslint-disable-next-line max-len
        : '値幅に基づいて、2回目以降の新規注文における順張り方向のエントリー条件を設定します。<br><br>【買いの場合】<br>決済価格からどのくらい上がったら、次新たに買うかを設定する、再エントリーの条件になります。<br>【売りの場合】<br>決済価格からどれくらい下がったら、次新たに売るかを設定する、再エントリーの条件になります。',
      descriptionImage: `multi${serviceName}_follow.png`,
    },
    counterCheckbox: {
      label: 'カウンター設定',
      tooltipMessage: '値幅又は価格に基づいて、2回目以降の新規注文における逆張り方向のエントリー条件を設定します。',
      descriptionImage: `multi${serviceName}_counter.png`,
    },
    counterInput: {
      label: isFX ? 'カウンター値(pips)' : 'カウンター値',
      // eslint-disable-next-line max-len, prettier/prettier
      // eslint-disable-next-line max-len
      tooltipMessage:
        '【買いの場合】<br>決済価格からどのくらい下がったら、次新たに買うかを設定する、再エントリーの条件になります。<br>【売りの場合】<br>決済価格からどれくらい上がったら、次新たに売るかを設定する、再エントリーの条件になります。',
      descriptionImage: `multi${serviceName}_counter.png`,
    },
    counterFixedCheckbox: {
      label: '固定する',
      tooltipMessage:
        // eslint-disable-next-line max-len, prettier/prettier
        'ONに設定した場合は、カウンター値が価格固定となります。決済後、各注文はそれぞれ決まった価格で新規発注を繰り返します。<br>OFFに設定した場合は、決済価格からカウンター値の値幅分離れたところに新規発注します。',
      // eslint-disable-next-line max-len, prettier/prettier
      descriptionImage: `multi${serviceName}_counter_fix.png`,
    },
  };
};
