/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useRef, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CFD, COUNTRY_TYPE, ETF, FX, QUANTITY_STEP } from '../../constants';
import {
  AMOUNT_PRECISION,
  BUILDER_ORDER_CONFIGURATION_OPTIONS,
  ENTRY_PRICE_OPTIONS,
  ENTRY_PRICE_PIPS_STEP,
  ETF_INSTRUMENT_WITH_QUANTITY_STEP_TEN,
  ETF_JPY_PRICE_STEP,
  BUILDER_TECH_ETF_NOT_JPY_PRICE_STEP,
  ITEMS_COUNT_STEP,
  RANGE_ETF_JPY_SPREAD_STEP,
  RANGE_ETF_NOT_JPY_SPREAD_STEP,
  RANGE_SPREAD_STEP,
  ETF_NOT_JPY_PRICE_STEP,
  FX_CFD_STEP,
  RANGE_CFD_SPREAD_STEP,
} from '../../constants/builder';
import {
  resetBuilderMultiOrderOptions,
  resetBuilderMultiOrderValidationErrors,
} from '../../redux/actions/builderActions';
import { getBuilderDefaultAmount } from '../../utils';
import { useBuilderTooltips } from '../../hooks/useBuilderTooltips';
import { getAPQuantityStep, getBuilderPriceStep } from '../index';
import {
  useBuilderCounter,
  useBuilderInstrumentSettings,
  useBuilderMultiDefaultSettings,
  useBuilderMultiDisabledValidationOptions,
  useBuilderMultiEntryPricePipsValue,
  useBuilderMultiEntryPriceValue,
  useBuilderMultiFollow,
  useBuilderMultiOption,
  useBuilderPricePrecision,
  useBuilderValidateAmount,
  useBuilderValidateCounter,
  useBuilderValidateEntryPipsPrice,
  useBuilderValidateEntryPrice,
  useBuilderValidateFollow,
  useBuilderValidateItemsCount,
  useBuilderValidateMultiOptions,
  useBuilderValidateProfitMargin,
  useBuilderValidateRangeSpread,
  useBuilderValidateStopLoss,
  useGetBuilderServiceId,
  useBuilderPutMultiOrderSettings,
  useBuilderMultiEditSettings,
} from './builder';

const useBuilderMultiFormLogic = (submitCallback, reset) => {
  const dispatch = useDispatch();

  const isChartDataLoading = useSelector((state) => state.builder.chartDataIsLoading);
  const chartData = useSelector((state) => state.builder.chartData);
  // チャートデータが1件以上あった場合は読み込み済みとする。
  // 但し、このフックが評価されるタイミングで既に読み込み済みかつチャートデータが0件だった場合は
  // チャートデータから算出した値ががフォームに設定されず、バリデーションもかからない
  const isLoadedMoreThanOnceRef = useRef(chartData?.length > 0);
  const isLoadedChartDataRef = useRef(false);
  const selectedSell = useSelector((state) => state.builder.multiOrder.selectedSellBuyId);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);
  const simulationDataIsLoading = useSelector((state) => state.builder.simulationDataIsLoading);
  const multiOrderValidationErrors = useSelector((state) => state.builder.multiOrderValidationErrors);
  const { isEditLogicGroup, editLogicGroupId, editDate } = useSelector((state) => state.builder.editLogicGroup);

  const tooltip = useBuilderTooltips();
  const serviceId = useGetBuilderServiceId();
  const isFX = serviceId === FX;
  const isETF = serviceId === ETF;
  const isCFD = serviceId === CFD;

  const { allowSellFlg, allowBuyFlg, quantityPrecision, quantityUnitConvFactor } = useBuilderInstrumentSettings();

  useEffect(() => {
    if (isChartDataLoading) {
      isLoadedMoreThanOnceRef.current = true;
      isLoadedChartDataRef.current = false;
    } else if (isLoadedMoreThanOnceRef.current) {
      isLoadedChartDataRef.current = true;
    }
  }, [isChartDataLoading]);

  const defaultSettings = useBuilderMultiDefaultSettings();
  const defaultSettingsRef = useRef({});
  useEffect(() => {
    if (defaultSettingsRef?.current) defaultSettingsRef.current = defaultSettings;
  }, [defaultSettings]);

  const editOrderSettings = useBuilderMultiEditSettings(editLogicGroupId);
  const editOrderSettingsRef = useRef({});
  useEffect(() => {
    if (editOrderSettingsRef?.current) editOrderSettingsRef.current = { ...editOrderSettings };
  }, [editOrderSettings]);

  const { pricePrecision, pipsPrecision } = useBuilderPricePrecision();

  const validateRangeSpread = useBuilderValidateRangeSpread();
  const validateItemsCount = useBuilderValidateItemsCount();
  const validateAmount = useBuilderValidateAmount();
  const validateEntryPrice = useBuilderValidateEntryPrice();
  const validateEntryPipsPrice = useBuilderValidateEntryPipsPrice();
  const validateProfitMargin = useBuilderValidateProfitMargin();
  const validateStopLoss = useBuilderValidateStopLoss();
  const validateFollow = useBuilderValidateFollow();
  const validateCounter = useBuilderValidateCounter();

  const [selectedSellBuyId, setSellBuyIdRaw] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.SELECTED_SELL_BUY_ID,
  });
  const [rangeSpread, setRangeSpreadRaw] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.RANGE_SPREAD,
    precision: pipsPrecision,
    validate: validateRangeSpread,
  });
  const [itemsCount, setItemsCount] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ITEMS_COUNT,
    validate: validateItemsCount,
  });
  const [amount, setAmount] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.AMOUNT,
    precision: isFX ? AMOUNT_PRECISION : -Math.log10(quantityPrecision),
    validate: validateAmount,
  });
  const [entryPriceTypeId, setEntryPriceTypeId] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_TYPE_ID,
  });
  const [entryPriceValue, setEntryPriceValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_VALUE,
    precision: pricePrecision,
    validate: validateEntryPrice,
  });
  const [entryPricePipsValue, setEntryPricePipsValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_PIPS_VALUE,
    precision: pipsPrecision,
    validate: validateEntryPipsPrice,
  });
  const [isOCOChecked, setIsOCOChecked] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.OCO_IS_CHECKED,
  });
  const [profitMargin, setProfitMargin] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.PROFIT_MARGIN,
    precision: pipsPrecision,
    validate: validateProfitMargin,
  });
  const [isStopLossSpreadChecked, setCheckStopLossSpread] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD_IS_CHECKED,
  });
  const [stopLossSpread, setStopLossSpread] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD,
    precision: pipsPrecision,
    validate: validateStopLoss,
  });
  const [isFollowValueChecked, setCheckFollowValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE_IS_CHECKED,
  });
  const [followValue, setFollowValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE,
    precision: pipsPrecision,
    validate: validateFollow,
  });
  const [isCounterValueChecked, setCheckCounterValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE_IS_CHECKED,
  });
  const [counterValue, setCounterValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE,
    precision: pipsPrecision,
    validate: validateCounter,
  });
  const [isCounterFixed, setCounterFixed] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_IS_FIXED,
  });

  const [hasViewedDetailSettings, setHasViewedDetailSettings] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.HAS_VIEWED_DETAIL_SETTINGS,
  });

  const putOrderSettings = useBuilderPutMultiOrderSettings(submitCallback);
  const validationDisabledOptions = useBuilderMultiDisabledValidationOptions();
  const validateOptions = useBuilderValidateMultiOptions();
  const validationOptionsRef = useRef(null);
  validationOptionsRef.current = validateOptions;

  const currentEntryPriceValue = useBuilderMultiEntryPriceValue(selectedSellBuyId);
  const currentEntryPricePipsValue = useBuilderMultiEntryPricePipsValue(selectedSellBuyId);

  useEffect(() => {
    if (isChartDataLoading) return;
    if (isLoadedChartDataRef.current) {
      validateOptions();
    }
  }, [validateOptions, isChartDataLoading]);

  const currentFollowValue = useBuilderMultiFollow(selectedSellBuyId);
  const currentCounterValue = useBuilderCounter(selectedSellBuyId);
  const currentSellBuyOptionsRef = useRef({});
  useEffect(() => {
    if (currentSellBuyOptionsRef?.current) {
      currentSellBuyOptionsRef.current = {
        currentFollowValue,
        currentCounterValue,
        currentEntryPriceValue,
        currentEntryPricePipsValue,
      };
    }
  }, [currentFollowValue, currentCounterValue, currentEntryPriceValue, currentEntryPricePipsValue]);

  const setValuesCallbacksRef = useRef({});
  useEffect(() => {
    if (setValuesCallbacksRef?.current) {
      setValuesCallbacksRef.current = {
        setAmount,
        setSellBuyId: setSellBuyIdRaw,
        setRangeSpread: setRangeSpreadRaw,
        setItemsCount,
        setEntryPriceValue,
        setEntryPricePipsValue,
        setProfitMargin,
        setStopLossSpread,
        setFollowValue,
        setCounterValue,
        setIsOCOChecked,
        setCheckFollowValue,
        setEntryPriceTypeId,
        setCheckCounterValue,
        setCounterFixed,
        setCheckStopLossSpread,
        setHasViewedDetailSettings,
      };
    }
  }, [
    selectedSell,
    setAmount,
    setSellBuyIdRaw,
    setRangeSpreadRaw,
    setItemsCount,
    setEntryPriceValue,
    setEntryPricePipsValue,
    setProfitMargin,
    setStopLossSpread,
    setFollowValue,
    setCounterValue,
    setIsOCOChecked,
    setCheckFollowValue,
    setEntryPriceTypeId,
    setCheckCounterValue,
    setCounterFixed,
    setCheckStopLossSpread,
    setHasViewedDetailSettings,
  ]);

  const resetState = useCallback(() => {
    const {
      defaultSelectedSellBuyId,
      defaultRangeSpread,
      defaultItemsCount,
      defaultEntryPriceValue,
      defaultEntryPricePipsValue,
      defaultProfitMargin,
      defaultStopLossSpread,
      defaultFollow,
      defaultCounterValue,
      defaultHasViewedDetailSettings,
    } = defaultSettingsRef.current;
    const {
      setAmount: setAmountRef,
      setSellBuyId: setSellBuyIdRef,
      setRangeSpread: setRangeSpreadRef,
      setItemsCount: setItemsCountRef,
      setEntryPriceValue: setEntryPriceValueRef,
      setEntryPricePipsValue: setEntryPricePipsValueRef,
      setProfitMargin: setProfitMarginRef,
      setStopLossSpread: setStopLossSpreadRef,
      setFollowValue: setFollowValueRef,
      setCounterValue: setCounterValueRef,
      setHasViewedDetailSettings: setHasViewedDetailSettingsRef,
    } = setValuesCallbacksRef.current;

    dispatch(resetBuilderMultiOrderOptions());
    dispatch(resetBuilderMultiOrderValidationErrors());

    const defaultAmount = getBuilderDefaultAmount(serviceId, instrumentId);
    setAmountRef(defaultAmount);
    setSellBuyIdRef(defaultSelectedSellBuyId);
    setRangeSpreadRef(defaultRangeSpread);
    setItemsCountRef(defaultItemsCount);
    setEntryPriceValueRef(defaultEntryPriceValue);
    setEntryPricePipsValueRef(defaultEntryPricePipsValue);
    setProfitMarginRef(defaultProfitMargin);
    setStopLossSpreadRef(defaultStopLossSpread);
    setFollowValueRef(defaultFollow);
    setCounterValueRef(defaultCounterValue);
    setHasViewedDetailSettingsRef(defaultHasViewedDetailSettings);
  }, [dispatch, serviceId, instrumentId]);

  const setEditValue = useCallback(() => {
    const { defaultFollow, defaultCounterValue, defaultStopLossSpread } = defaultSettingsRef.current;
    const {
      buySell: editBuySell,
      entryPriceType: editEntryPriceType,
      entryPriceValue: editEntryPriceValue,
      entryPricePipsValue: editEntryPricePipsValue,
      ocoIsChecked: ocoIsCheckedEditVal,
      rangeSpread: rangeSpreadEditVal,
      amount: amountEditVal,
      counterValue: counterValueEditVal,
      profitMargin: profitMarginEditVal,
      stopLossRange: stopLossRangeEditVal,
      follow: followEditVal,
      counter: counterEditVal,
      counterIsFixed: counterIsFixedEditVal,
      itemsCount: itemsCountEditVal,
      hasViewedDetailSettings: hasViewedDetailSettingsEditVal,
    } = editOrderSettingsRef.current;
    const {
      setAmount: setAmountRef,
      setSellBuyId: setSellBuyIdRef,
      setRangeSpread: setRangeSpreadRef,
      setItemsCount: setItemsCountRef,
      setEntryPriceValue: setEntryPriceValueRef,
      setEntryPricePipsValue: setEntryPricePipsValueRef,
      setProfitMargin: setProfitMarginRef,
      setCheckStopLossSpread: setCheckStopLossSpreadRef,
      setStopLossSpread: setStopLossSpreadRef,
      setFollowValue: setFollowValueRef,
      setCounterValue: setCounterValueRef,
      setIsOCOChecked: setIsOCOCheckedRef,
      setCheckFollowValue: setCheckFollowValueRef,
      setEntryPriceTypeId: setEntryPriceTypeIdRef,
      setCheckCounterValue: setCheckCounterValueRef,
      setCounterFixed: setCounterFixedRef,
      setHasViewedDetailSettings: setHasViewedDetailSettingsRef,
    } = setValuesCallbacksRef.current;

    setSellBuyIdRef(editBuySell); // 売買
    setEntryPriceTypeIdRef(editEntryPriceType); // 価格指定/前日終値
    setEntryPriceValueRef(editEntryPriceValue); // 価格
    setEntryPricePipsValueRef(editEntryPricePipsValue); // Pips
    setIsOCOCheckedRef(ocoIsCheckedEditVal); // OCO
    setRangeSpreadRef(rangeSpreadEditVal); // レンジ幅
    setAmountRef(amountEditVal); // 数量
    setItemsCountRef(itemsCountEditVal); // 本数
    setProfitMarginRef(profitMarginEditVal); // 利確幅
    setCounterValueRef(counterValueEditVal);
    const isCheckStopLossSpread = !!stopLossRangeEditVal;
    setCheckStopLossSpreadRef(isCheckStopLossSpread);
    setStopLossSpreadRef(isCheckStopLossSpread ? stopLossRangeEditVal : defaultStopLossSpread); // 損切幅
    const isCheckFollow = !!followEditVal;
    setCheckFollowValueRef(isCheckFollow);
    setFollowValueRef(isCheckFollow ? followEditVal : defaultFollow); // フォロー
    const isCheckCounter = !!counterEditVal;
    setCheckCounterValueRef(isCheckCounter);
    setCounterValueRef(isCheckCounter ? counterEditVal : defaultCounterValue); // カウンター値
    setCounterFixedRef(counterIsFixedEditVal);
    setHasViewedDetailSettingsRef(hasViewedDetailSettingsEditVal); // 詳細設定表示済み
  }, [editOrderSettingsRef]);

  const partialReset = useCallback(() => {
    const { defaultEntryPriceValue, defaultEntryPricePipsValue, defaultFollow, defaultCounterValue } =
      defaultSettingsRef.current;
    const {
      setEntryPriceValue: setEntryPriceValueRef,
      setEntryPricePipsValue: setEntryPricePipsValueRef,
      setFollowValue: setFollowValueRef,
      setCounterValue: setCounterValueRef,
    } = setValuesCallbacksRef.current;

    setEntryPriceValueRef(defaultEntryPriceValue);
    setEntryPricePipsValueRef(defaultEntryPricePipsValue);
    setFollowValueRef(defaultFollow);
    setCounterValueRef(defaultCounterValue);
    setTimeout(() => {
      validationOptionsRef.current();
    });
  }, []);

  const setSellBuyId = useCallback(
    (value) => {
      setSellBuyIdRaw(value);
      setTimeout(() => partialReset());
    },
    [setSellBuyIdRaw, partialReset],
  );

  const setRangeSpread = useCallback(
    (value) => {
      setRangeSpreadRaw(value);
      setTimeout(() => {
        validationOptionsRef.current();
      });
    },
    [setRangeSpreadRaw],
  );

  useEffect(() => {
    if (!isChartDataLoading && reset && isLoadedChartDataRef.current) {
      // 読み込んだデータから算出したデフォルト値を反映
      resetState();
    }
  }, [isChartDataLoading, resetState, reset]);

  useEffect(() => {
    if (!isChartDataLoading && isEditLogicGroup && isLoadedChartDataRef.current) {
      // 編集ボタンが押下されたロジック(ルール)情報を反映
      setEditValue();
    }
  }, [isChartDataLoading, isEditLogicGroup, setEditValue, editDate]);

  const amountStep = useMemo(() => {
    if (isFX) {
      return getAPQuantityStep(instrumentId);
    }
    if (isCFD) {
      return QUANTITY_STEP.ZERO_POINT_ONE;
    }
    if (instrumentId === ETF_INSTRUMENT_WITH_QUANTITY_STEP_TEN) {
      return QUANTITY_STEP.TEN;
    }
    return QUANTITY_STEP.ONE;
  }, [isFX, isCFD, instrumentId]);

  const startPriceStep = useMemo(() => {
    if (isFX) {
      return getBuilderPriceStep(instrumentId);
    }
    if (isCFD) {
      return FX_CFD_STEP;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return ETF_JPY_PRICE_STEP;
    }
    return ETF_NOT_JPY_PRICE_STEP;
  }, [isFX, isCFD, instrumentId]);

  const priceStep = useMemo(() => {
    if (!isETF) {
      return FX_CFD_STEP;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return ETF_JPY_PRICE_STEP;
    }
    return BUILDER_TECH_ETF_NOT_JPY_PRICE_STEP;
  }, [isETF, instrumentId]);

  const rangeStep = useMemo(() => {
    if (isFX) {
      return RANGE_SPREAD_STEP;
    }
    if (isCFD) {
      return RANGE_CFD_SPREAD_STEP;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return RANGE_ETF_JPY_SPREAD_STEP;
    }
    return RANGE_ETF_NOT_JPY_SPREAD_STEP;
  }, [isFX, isCFD, instrumentId]);

  const errorMessages = useMemo(() => {
    return Object.entries(multiOrderValidationErrors).reduce((messages, [inputName, info]) => {
      if (info.hasValidationError && !validationDisabledOptions.includes(inputName)) {
        messages.push({ inputName, errorMessage: info.errorMessage });
      }
      return messages;
    }, []);
  }, [multiOrderValidationErrors, validationDisabledOptions]);

  const isCounterFollowNotValid = useMemo(
    () => !isFollowValueChecked && !isCounterValueChecked,
    [isFollowValueChecked, isCounterValueChecked],
  );

  const isAddLogicDisabled = useMemo(
    () =>
      isChartDataLoading ||
      !allowOrderEdit ||
      simulationDataIsLoading ||
      errorMessages.length > 0 ||
      isCounterFollowNotValid,
    [isChartDataLoading, allowOrderEdit, simulationDataIsLoading, errorMessages, isCounterFollowNotValid],
  );

  /** ロジック(ルール)追加・変更. */
  const putLogic = useCallback(() => {
    const { hasErrors } = validateOptions();
    if (hasErrors || isAddLogicDisabled) return;
    putOrderSettings();
  }, [putOrderSettings, validateOptions, isAddLogicDisabled]);

  const setProfitHandler = (value) => {
    setProfitMargin(value);
    if (!hasViewedDetailSettings) {
      setCounterValue(-Number(value));
    }
  };

  return {
    errorMessages,
    reset: {
      handler: resetState,
      isDisabled: isChartDataLoading,
    },
    assets: {
      label: tooltip.assets.label,
    },
    buySell: {
      label: tooltip.buySell.label,
      get: selectedSellBuyId,
      set: setSellBuyId,
      isSellDisabled: !allowSellFlg,
      isBuyDisabled: !allowBuyFlg,
      isDisabled: isChartDataLoading,
      tooltipMessage: tooltip.buySell.tooltipMessage,
      descriptionImage: tooltip.buySell.descriptionImage,
    },
    rangeSpread: {
      label: tooltip.rangeSpread.label,
      get: rangeSpread,
      set: setRangeSpread,
      isDisabled: isChartDataLoading,
      step: rangeStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.RANGE_SPREAD,
      tooltipMessage: tooltip.rangeSpread.tooltipMessage,
      descriptionImage: tooltip.rangeSpread.descriptionImage,
    },
    range: {
      label: tooltip.range.label,
    },
    itemsCount: {
      label: tooltip.itemsCount.label,
      get: itemsCount,
      set: setItemsCount,
      isDisabled: isChartDataLoading,
      step: ITEMS_COUNT_STEP,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ITEMS_COUNT,
      tooltipMessage: tooltip.itemsCount.tooltipMessage,
      descriptionImage: tooltip.itemsCount.descriptionImage,
    },
    quantity: {
      label: tooltip.quantity.label,
      get: amount,
      set: setAmount,
      isDisabled: isChartDataLoading,
      step: amountStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.AMOUNT,
      tooltipMessage: tooltip.quantity.tooltipMessage,
      descriptionImage: tooltip.quantity.descriptionImage,
    },
    quantityUnitConvFactor: {
      label: `1Lot=${quantityUnitConvFactor}`,
    },
    entryPriceSwitch: {
      label: 'レンジ',
      options: ENTRY_PRICE_OPTIONS,
      get: entryPriceTypeId,
      set: setEntryPriceTypeId,
      isDisabled: isChartDataLoading,
    },
    entryPriceInput: {
      get: entryPriceValue,
      set: setEntryPriceValue,
      isDisabled: isChartDataLoading,
      step: startPriceStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_VALUE,
    },
    entryPricePipsValue: {
      label: tooltip.entryPricePipsValue.label,
      get: entryPricePipsValue,
      set: setEntryPricePipsValue,
      isDisabled: isChartDataLoading,
      step: isFX ? ENTRY_PRICE_PIPS_STEP : startPriceStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_PIPS_VALUE,
      min: null,
      tooltipMessage: tooltip.entryPricePipsValue.tooltipMessage,
      descriptionImage: tooltip.entryPricePipsValue.descriptionImage,
    },
    viewedDetailSettings: {
      get: hasViewedDetailSettings,
      set: setHasViewedDetailSettings,
    },
    ocoCheckbox: {
      label: tooltip.ocoCheckbox.label,
      get: isOCOChecked,
      set: setIsOCOChecked,
      isDisabled: isChartDataLoading,
      tooltipMessage: tooltip.ocoCheckbox.tooltipMessage,
      descriptionImage: tooltip.ocoCheckbox.descriptionImage,
    },
    profitMargin: {
      label: tooltip.profitMargin.label,
      get: profitMargin,
      set: setProfitHandler,
      isDisabled: isChartDataLoading,
      step: priceStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.PROFIT_MARGIN,
      tooltipMessage: tooltip.profitMargin.tooltipMessage,
      descriptionImage: tooltip.profitMargin.descriptionImage,
    },
    stopLossCheckbox: {
      label: tooltip.stopLossCheckbox.label,
      get: isStopLossSpreadChecked,
      set: setCheckStopLossSpread,
      isDisabled: isChartDataLoading,
      tooltipMessage: tooltip.stopLossCheckbox.tooltipMessage,
      descriptionImage: tooltip.stopLossCheckbox.descriptionImage,
    },
    stopLossInput: {
      get: stopLossSpread,
      set: setStopLossSpread,
      step: priceStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD,
      min: null,
      isDisabled: !isStopLossSpreadChecked || isChartDataLoading,
    },
    followCheckbox: {
      label: tooltip.followCheckbox.label,
      get: isFollowValueChecked,
      set: setCheckFollowValue,
      isDisabled: isChartDataLoading,
      tooltipMessage: tooltip.followCheckbox.tooltipMessage,
      descriptionImage: tooltip.followCheckbox.descriptionImage,
    },
    followInput: {
      get: followValue,
      set: setFollowValue,
      isDisabled: !isFollowValueChecked || isChartDataLoading,
      step: priceStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE,
      min: null,
    },
    counterCheckbox: {
      label: tooltip.counterCheckbox.label,
      get: isCounterValueChecked,
      set: setCheckCounterValue,
      isDisabled: isChartDataLoading,
      tooltipMessage: tooltip.counterCheckbox.tooltipMessage,
      descriptionImage: tooltip.counterCheckbox.descriptionImage,
    },
    counterInput: {
      label: tooltip.counterInput.label,
      get: counterValue,
      set: setCounterValue,
      isDisabled: !isCounterValueChecked || isChartDataLoading,
      step: priceStep,
      min: null,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE,
      tooltipMessage: tooltip.counterInput.tooltipMessage,
      descriptionImage: tooltip.counterInput.descriptionImage,
    },
    counterFixedCheckbox: {
      get: isCounterFixed,
      set: setCounterFixed,
      label: tooltip.counterFixedCheckbox.label,
      isDisabled: !isCounterValueChecked || isChartDataLoading,
      tooltipMessage: tooltip.counterFixedCheckbox.tooltipMessage,
      descriptionImage: tooltip.counterFixedCheckbox.descriptionImage,
    },
    invalidCounter: {
      condition: isCounterFollowNotValid,
      message: 'フォロー値かカウンター値のいずれかをご設定ください',
    },
    submit: {
      label: isEditLogicGroup ? 'ルール変更' : 'ルール作成',
      handler: putLogic,
      isDisabled: isAddLogicDisabled,
    },
  };
};

export default useBuilderMultiFormLogic;
