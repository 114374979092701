import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BUILDER_EXCHANGE_TYPES, KEY_FOR_DEFAULT_SELECTED_BUILDER_INSTRUMENTS } from 'shared-modules/constants/builder';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import { CFD, ETF, FX } from 'shared-modules/constants';
import { useChangeService } from 'shared-modules/services/hooks';
import { saveDefaultValuesFromLocalStorage } from 'shared-modules/services';
import {
  changeBuilderActiveCurrency,
  changeBuilderExchangeType,
  changeServiceIdRequest,
  getBuilderChartDataRequest,
} from 'shared-modules/redux/actions';
import { useGetBuilderServiceId } from 'shared-modules/services/hooks/builder';
import { useServiceName } from 'shared-modules/hooks';
import styles from './instrumentSelection.module.scss';
import { CfdInstrumentIcon, CustomFlag, EtfInstrumentIcon, Tabs } from '../../../../../components';

export const InstrumentSelection = ({ setIssueIsHovered }) => {
  const dispatch = useDispatch();
  const [serviceId, setServiceId] = useState(useGetBuilderServiceId());
  const instrumentOptions = useApOrManualInstrumentOptions(false, serviceId);
  const memoizedOptions = useMemo(() => instrumentOptions, [instrumentOptions]);
  const tradeServiceData = useChangeService();
  const [tabWidth, setTabWidth] = useState(344 / ((tradeServiceData.options?.length ?? 0) + 1));
  const handleResize = useCallback(
    ({ width }) => {
      const length = tradeServiceData.options?.length ?? 0;
      if (length > 0) {
        setTabWidth((width ?? 0) / length);
      }
    },
    [tradeServiceData.options],
  );

  const handleMouseEnter = () => {
    setIssueIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIssueIsHovered(false);
  };

  const issueMouseHover = (event) => {
    event.currentTarget.classList.add(styles.instrumentSelectionButtonSelected);
  };

  const issueMouseHoverEnd = (event) => {
    event.currentTarget.classList.remove(styles.instrumentSelectionButtonSelected);
  };

  const handleServiceId = (id) => {
    setServiceId(id);
  };

  const serviceName = useServiceName(serviceId);
  const renderCurrencyItems = useMemo(() => {
    return memoizedOptions[serviceId].map((currency) => {
      const { label, value } = currency || {};
      const [firstFlag, secondFlag] = label?.split('/') || [];
      const content = () => {
        if (serviceId === FX) {
          return (
            <>
              <CustomFlag className={styles.icon} country={firstFlag} />
              <CustomFlag className={styles.icon} country={secondFlag} />
            </>
          );
        }
        if (serviceId === ETF) {
          return <EtfInstrumentIcon className={styles.icon} instrumentId={value} size="medium" />;
        }
        if (serviceId === CFD) {
          return <CfdInstrumentIcon className={styles.icon} instrumentId={value} size="medium" />;
        }
        return null;
      };

      const handleClick = () => {
        dispatch(changeBuilderActiveCurrency({ activeCurrency: currency.value }));
        dispatch(changeServiceIdRequest({ serviceId }));
        dispatch(changeBuilderExchangeType({ exchangeType: BUILDER_EXCHANGE_TYPES[serviceName].ID }));
        dispatch(getBuilderChartDataRequest());
        saveDefaultValuesFromLocalStorage({
          key: KEY_FOR_DEFAULT_SELECTED_BUILDER_INSTRUMENTS[serviceId],
          value: JSON.stringify(currency),
        });
        setIssueIsHovered(false);
      };

      return (
        <div
          key={value}
          role="button"
          onClick={handleClick}
          tabIndex={0}
          className={styles.instrumentSelectionButton}
          onMouseOver={(event) => issueMouseHover(event)}
          onMouseOut={(event) => issueMouseHoverEnd(event)}
          onFocus={(event) => issueMouseHover(event)}
          onBlur={(event) => issueMouseHoverEnd(event)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleClick();
            }
          }}
        >
          {content()}
          <span className={styles.currency}>{label}</span>
        </div>
      );
    });
  }, [dispatch, serviceId, setIssueIsHovered, serviceName, memoizedOptions]);

  return (
    <div className={styles.issueWrapper} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={styles.tabWrapper}>
        <Tabs
          tabMinWidth={80}
          scrollWidth={tabWidth}
          containerClassName={styles.tabs}
          items={tradeServiceData.options}
          activeKey={serviceId}
          onChange={handleServiceId}
          onReady={handleResize}
          onResize={handleResize}
        />
      </div>
      <div className={styles.instrumentSelectionWrapper}>{renderCurrencyItems}</div>
    </div>
  );
};

InstrumentSelection.propTypes = {
  setIssueIsHovered: PropTypes.func.isRequired,
};
