import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { BUILDER_ORDER_TYPES } from 'shared-modules/constants/builder';
import { getServiceQuantityUnit } from 'shared-modules/services';
import { getPipsLabelWithParentheses } from 'shared-modules/utils';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import { deleteBuilderOrderSettingsById } from '../../../../../redux/actions';
import { Table } from '../../../../../components';
import { Empty } from './Empty';
import { SeqCell, BuySellCell, NumberCell as Cell } from './Cell';
import { DeleteIcon } from './DeleteIcon';
import styles from './chartSettings.module.scss';

const orderSettingsResizedSingle = {
  key: 'orderSettingsResizedSingle',
  default: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
};

const orderSettingsResizedMulti = {
  key: 'orderSettingsResizedMulti',
  default: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
};

export const OrderSettingsTable = memo(({ tableType }) => {
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.builder.orderSettingsList);
  const orderType = useSelector((state) => state.builder.orderType);
  const isSingle = useMemo(() => orderType === BUILDER_ORDER_TYPES.SINGLE.ID, [orderType]);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const pipsLabelWithParentheses = getPipsLabelWithParentheses(serviceId, instrumentId);
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteBuilderOrderSettingsById({ id }));
    },
    [dispatch],
  );

  const builderColumns = useMemo(
    () => [
      {
        Header: '番号',
        accessor: 'number',
        disableSortBy: true,
        Cell: SeqCell,
        minWidth: 42,
      },
      {
        Header: '銘柄',
        accessor: 'instrumentId',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => removeSuffix(value),
        minWidth: 115,
      },
      {
        Header: '売買',
        accessor: 'buySell',
        Cell: BuySellCell,
        disableSortBy: true,
        minWidth: 42,
      },
      {
        Header: `数量(${quantityUnit})`,
        accessor: 'amount',
        isNumber: true,
        disableSortBy: true,
        minWidth: 64,
      },
      {
        Header: '価格1',
        accessor: 'entryPrice1',
        Cell,
        isNumber: true,
        disableSortBy: true,
        minWidth: 92,
      },
      {
        Header: '価格2',
        accessor: 'entryPrice2',
        isNumber: true,
        Cell,
        disableSortBy: true,
        minWidth: 92,
      },
      {
        Header: `利確幅${pipsLabelWithParentheses}`,
        accessor: 'profitMargin',
        isNumber: true,
        Cell,
        disableSortBy: true,
        minWidth: 92,
      },
      {
        Header: `損切幅${pipsLabelWithParentheses}`,
        accessor: 'stopLossRange',
        isNumber: true,
        Cell,
        disableSortBy: true,
        minWidth: 92,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        isNumber: true,
        Cell,
        disableSortBy: true,
        minWidth: 78,
      },
      {
        Header: 'カウンター値(価格指定)',
        // can't pass in multi accessor values, so use function -> we can customize what to display simply
        accessor: ({ counterPrice, counter }) => (counterPrice || counterPrice === 0 ? `(${counterPrice})` : counter),
        Cell,
        isNumber: true,
        disableSortBy: true,
        minWidth: 150,
      },
    ],
    [pipsLabelWithParentheses, quantityUnit],
  );

  const techColumns = useMemo(
    () => [
      {
        Header: '番号',
        accessor: 'number',
        disableSortBy: true,
        Cell: SeqCell,
      },
      {
        Header: '銘柄',
        accessor: 'instrumentId',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => removeSuffix(value),
      },
      {
        Header: '売買',
        accessor: 'buySell',
        Cell: BuySellCell,
        disableSortBy: true,
      },
      {
        Header: `数量(${quantityUnit})`,
        accessor: 'amount',
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: 'エントリー価格1',
        accessor: 'entryPrice1',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: `利確幅${pipsLabelWithParentheses}`,
        accessor: 'profitMargin',
        isNumber: true,
        Cell,
        disableSortBy: true,
      },
      {
        Header: `損切幅${pipsLabelWithParentheses}`,
        accessor: 'stopLossRange',
        isNumber: true,
        Cell,
        disableSortBy: true,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        isNumber: true,
        Cell,
        disableSortBy: true,
      },
      {
        Header: 'カウンター値(価格指定)',
        // can't pass in multi accessor values, so use function -> we can customize what to display simply
        accessor: ({ counterPrice, counter }) => (counterPrice || counterPrice === 0 ? `(${counterPrice})` : counter),
        Cell,
        isNumber: true,
        disableSortBy: true,
        minWidth: 90,
      },
    ],
    [pipsLabelWithParentheses, quantityUnit],
  );

  const columns = useMemo(() => {
    if (!isSingle) {
      switch (tableType) {
        case 'builder':
          return builderColumns;
        case 'tech':
          return techColumns;
        default:
          return [];
      }
    }
    return [
      ...builderColumns,
      {
        Header: '',
        accessor: 'options',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { id },
          },
        }) => allowOrderEdit && <DeleteIcon id={id} onClick={handleDelete} />,
        disableSortBy: true,
        sticky: 'right',
      },
    ];
  }, [isSingle, builderColumns, techColumns, handleDelete, tableType, allowOrderEdit]);

  if (!tableData.length) {
    return (
      <Empty
        title="追加されたルールはありません。"
        desc={`${tableType === 'tech' ? '' : '複数の'}ルールを追加することができます。`}
      />
    );
  }
  const resized = isSingle ? orderSettingsResizedSingle : orderSettingsResizedMulti;
  return (
    <Table
      tableData={tableData}
      columns={columns}
      resized={resized}
      key={0}
      rowHight={35}
      headerClassName={styles.tableHeader}
      rowClassName={styles.tableRow}
    />
  );
});

OrderSettingsTable.propTypes = {
  tableType: PropTypes.string,
};

OrderSettingsTable.defaultProps = {
  tableType: 'builder',
};
