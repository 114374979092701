import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { BUY_SELL_MAIN, CFD, DISABLE_INSTRUMENTS, FX } from 'shared-modules/constants';
import { BUILDER_PAGES } from 'shared-modules/constants/builder';
import useBuilderMultiFormLogic from 'shared-modules/services/hooks/builderMultiFormLogic';
import PropTypes from 'prop-types';
import { useBuilderFinalPrice, useBuilderPricePrecision } from 'shared-modules/services/hooks/builder';
import { BackLink, Button, BuySellGroupButton, InputNumber, Switch, HelpHalfModal } from '../../../../../components';
import {
  changeBuilderActivePage,
  resetBuilderChartData,
  resetBuilderMultiOrderOptions,
  resetBuilderOrdersTables,
} from '../../../../../redux/actions';
import styles from './sideMenu.module.scss';
import infoImagesHelper from '../../../../../assets/builderInfo/infoImagesHelper';
import { InstrumentSelectionButton } from './InstrumentSelectionButton/index';

const shortNumberInputWidth = 107;

export const SideMenu = memo(({ setIssueIsHovered }) => {
  const dispatch = useDispatch();
  const multiFormLogic = useBuilderMultiFormLogic(undefined, true);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const addedLogicGroups = useSelector((state) => state.builder.logicGroupsList);
  const hasLogicGroups = useMemo(() => addedLogicGroups.length > 0, [addedLogicGroups.length]);
  const [detailSettingIsOpen, setDetailSettingIsOpen] = useState(false);
  const [isFocusInputRange, setIsFocusInputRange] = useState(false);

  const { selectedSellBuyId } = useSelector((state) => state.builder.multiOrder);
  const isBuy = useMemo(() => Number(selectedSellBuyId) === BUY_SELL_MAIN.BUY.ID, [selectedSellBuyId]);
  const { pricePrecision } = useBuilderPricePrecision();
  const finalPrice = useBuilderFinalPrice();
  const isFX = serviceId === FX;

  const handleClick = useCallback(() => {
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.BUILDER_TYPE_PAGE.ID }));
    dispatch(resetBuilderMultiOrderOptions());
    dispatch(resetBuilderOrdersTables());
    dispatch(resetBuilderChartData());
  }, [dispatch]);

  const formatPriceValue = useCallback((value) => Number(value).toFixed(pricePrecision), [pricePrecision]);

  const isTQQQ = DISABLE_INSTRUMENTS.some((ins) => ins === instrumentId);

  const handleMouseEnter = () => {
    setIssueIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIssueIsHovered(false);
  };

  const toggleDetailSetting = () => {
    setDetailSettingIsOpen((prev) => !prev);
    multiFormLogic.viewedDetailSettings.set(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.row}>
          <BackLink onClick={handleClick}>選択に戻る</BackLink>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.settings}>
          <div className={styles.item}>
            <div className={styles.label}>銘柄</div>
            <div
              onMouseEnter={hasLogicGroups ? undefined : handleMouseEnter}
              onMouseLeave={hasLogicGroups ? undefined : handleMouseLeave}
            >
              <InstrumentSelectionButton disabled={hasLogicGroups} />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.buySell.label}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.buySell.tooltipMessage}
                logicName={multiFormLogic.buySell.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.buySell.descriptionImage]}
              />
            </div>
            <BuySellGroupButton
              buySell={isTQQQ ? BUY_SELL_MAIN.SELL.ID : multiFormLogic.buySell.get}
              onChange={multiFormLogic.buySell.set}
              disabledSell={multiFormLogic.buySell.isSellDisabled}
              disabledBuy={multiFormLogic.buySell.isBuyDisabled || isTQQQ}
              isNotSelectable={multiFormLogic.buySell.isNotSelectable}
            />
          </div>
          <div className={classNames(styles.item, styles.itemPaddingTop, styles.itemBorderBottomNone)}>
            <div className={styles.label}>
              {multiFormLogic.entryPricePipsValue.label}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.entryPricePipsValue.tooltipMessage}
                logicName={multiFormLogic.entryPricePipsValue.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.entryPricePipsValue.descriptionImage]}
              />
            </div>
            {isBuy ? (
              <InputNumber
                className={styles.numberInput}
                width={shortNumberInputWidth}
                onFocus={() => setIsFocusInputRange(true)}
                onBlur={() => setIsFocusInputRange(false)}
                value={
                  isFocusInputRange
                    ? multiFormLogic.entryPriceInput.get
                    : formatPriceValue(multiFormLogic.entryPriceInput.get)
                }
                onChange={multiFormLogic.entryPriceInput.set}
                disabled={multiFormLogic.entryPriceInput.isDisabled}
                step={multiFormLogic.entryPriceInput.step}
                min={multiFormLogic.entryPricePipsValue.min}
                name={multiFormLogic.entryPriceInput.name}
                errorMessages={multiFormLogic.errorMessages}
                withErrorTooltip
                validateNegativeValues
                inputStyle={styles.numberInputFont}
                buttonStyle={styles.numberInputButton}
              />
            ) : (
              <div className={styles.readOnlyContainer}>
                <div className={styles.input}>{finalPrice}</div>
              </div>
            )}
          </div>
          <div className={styles.itemRangeIconWrapper}>
            <div className={styles.itemRangeIcon}>〜</div>
          </div>
          <div className={classNames(styles.item, styles.itemBorderBottomNone, styles.right, styles.itemPaddingBottom)}>
            {!isBuy ? (
              <InputNumber
                className={styles.numberInput}
                width={shortNumberInputWidth}
                onFocus={() => setIsFocusInputRange(true)}
                onBlur={() => setIsFocusInputRange(false)}
                value={
                  isFocusInputRange
                    ? multiFormLogic.entryPriceInput.get
                    : formatPriceValue(multiFormLogic.entryPriceInput.get)
                }
                onChange={multiFormLogic.entryPriceInput.set}
                disabled={multiFormLogic.entryPriceInput.isDisabled}
                step={multiFormLogic.entryPriceInput.step}
                min={multiFormLogic.entryPricePipsValue.min}
                name={multiFormLogic.entryPriceInput.name}
                errorMessages={multiFormLogic.errorMessages}
                withErrorTooltip
                validateNegativeValues
                inputStyle={styles.numberInputFont}
                buttonStyle={styles.numberInputButton}
              />
            ) : (
              <div className={styles.readOnlyContainer}>
                <div className={styles.input}>{finalPrice}</div>
              </div>
            )}
          </div>

          <div className={classNames(styles.item, styles.itemPaddingBottom)}>
            <div className={styles.label}>
              {multiFormLogic.rangeSpread.label}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.rangeSpread.tooltipMessage}
                logicName={multiFormLogic.rangeSpread.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.rangeSpread.descriptionImage]}
              />
            </div>
            <InputNumber
              className={styles.numberInput}
              width={shortNumberInputWidth}
              value={multiFormLogic.rangeSpread.get}
              onChange={multiFormLogic.rangeSpread.set}
              disabled={multiFormLogic.rangeSpread.isDisabled}
              step={multiFormLogic.rangeSpread.step}
              name={multiFormLogic.rangeSpread.name}
              errorMessages={multiFormLogic.errorMessages}
              withErrorTooltip
              onlyIntegerAllowed
              inputStyle={styles.numberInputFont}
              buttonStyle={styles.numberInputButton}
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.itemsCount.label}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.itemsCount.tooltipMessage}
                logicName={multiFormLogic.itemsCount.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.itemsCount.descriptionImage]}
              />
            </div>
            <InputNumber
              className={styles.numberInput}
              width={shortNumberInputWidth}
              value={multiFormLogic.itemsCount.get}
              onChange={multiFormLogic.itemsCount.set}
              disabled={multiFormLogic.itemsCount.isDisabled}
              step={multiFormLogic.itemsCount.step}
              name={multiFormLogic.itemsCount.name}
              errorMessages={multiFormLogic.errorMessages}
              withErrorTooltip
              onlyIntegerAllowed
              inputStyle={styles.numberInputFont}
              buttonStyle={styles.numberInputButton}
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              <div className={styles.twoLinesLabel}>
                <div>{multiFormLogic.quantity.label}</div>
                {serviceId === CFD && <div className={styles.unit}>{multiFormLogic.quantityUnitConvFactor.label}</div>}
              </div>
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.quantity.tooltipMessage}
                logicName={multiFormLogic.quantity.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.quantity.descriptionImage]}
              />
            </div>
            <InputNumber
              className={styles.numberInput}
              width={shortNumberInputWidth}
              value={multiFormLogic.quantity.get}
              onChange={multiFormLogic.quantity.set}
              disabled={multiFormLogic.quantity.isDisabled}
              step={multiFormLogic.quantity.step}
              name={multiFormLogic.quantity.name}
              errorMessages={multiFormLogic.errorMessages}
              withErrorTooltip
              inputStyle={styles.numberInputFont}
              buttonStyle={styles.numberInputButton}
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.profitMargin.label}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.profitMargin.tooltipMessage}
                logicName={multiFormLogic.profitMargin.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.profitMargin.descriptionImage]}
              />
            </div>
            <InputNumber
              className={styles.numberInput}
              width={shortNumberInputWidth}
              value={multiFormLogic.profitMargin.get}
              onChange={multiFormLogic.profitMargin.set}
              disabled={multiFormLogic.profitMargin.isDisabled}
              step={multiFormLogic.profitMargin.step}
              name={multiFormLogic.profitMargin.name}
              errorMessages={multiFormLogic.errorMessages}
              withErrorTooltip
              inputStyle={styles.numberInputFont}
              buttonStyle={styles.numberInputButton}
            />
          </div>
          <div
            className={classNames(styles.item, styles.detailSetting)}
            onClick={toggleDetailSetting}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                toggleDetailSetting();
              }
            }}
          >
            詳細設定
            <i
              className={classNames(
                'material-icons-outlined',
                styles.infoIcon,
                detailSettingIsOpen ? null : styles.iconRotateDown,
              )}
            >
              keyboard_arrow_up
            </i>
          </div>
        </div>
        {detailSettingIsOpen ? (
          <div>
            <div className={styles.title}>詳細設定</div>
            <div className={styles.settings}>
              <div className={styles.item}>
                <div className={styles.label}>
                  {multiFormLogic.ocoCheckbox.label}
                  <HelpHalfModal
                    className={styles.help}
                    content={multiFormLogic.ocoCheckbox.tooltipMessage}
                    logicName={multiFormLogic.ocoCheckbox.label}
                    logicImageSrc={infoImagesHelper[multiFormLogic.ocoCheckbox.descriptionImage]}
                  />
                </div>
                <Switch
                  checked={multiFormLogic.ocoCheckbox.get}
                  onChange={multiFormLogic.ocoCheckbox.set}
                  disabled={multiFormLogic.ocoCheckbox.isDisabled}
                />
              </div>
              <div
                className={classNames(
                  styles.item,
                  multiFormLogic.stopLossCheckbox.get ? [styles.itemBorderBottomNone, styles.itemPaddingTop] : null,
                )}
              >
                <div className={styles.label}>
                  {multiFormLogic.stopLossCheckbox.label}
                  {isFX ? `(pips)` : ``}
                  <HelpHalfModal
                    className={styles.help}
                    content={multiFormLogic.stopLossCheckbox.tooltipMessage}
                    logicName={multiFormLogic.stopLossCheckbox.label}
                    logicImageSrc={infoImagesHelper[multiFormLogic.stopLossCheckbox.descriptionImage]}
                  />
                </div>
                <Switch
                  checked={multiFormLogic.stopLossCheckbox.get}
                  onChange={multiFormLogic.stopLossCheckbox.set}
                  disabled={multiFormLogic.stopLossCheckbox.isDisabled}
                />
              </div>
              {multiFormLogic.stopLossCheckbox.get && (
                <div className={classNames(styles.item, styles.right)}>
                  <InputNumber
                    className={styles.numberInput}
                    width={shortNumberInputWidth}
                    value={multiFormLogic.stopLossInput.get}
                    onChange={multiFormLogic.stopLossInput.set}
                    disabled={multiFormLogic.stopLossInput.isDisabled}
                    step={multiFormLogic.stopLossInput.step}
                    min={multiFormLogic.stopLossInput.min}
                    name={multiFormLogic.stopLossInput.name}
                    errorMessages={multiFormLogic.errorMessages}
                    withErrorTooltip
                    validateNegativeValues
                    inputStyle={styles.numberInputFont}
                    buttonStyle={styles.numberInputButton}
                  />
                </div>
              )}
              <div
                className={classNames(
                  styles.counterSetting,
                  multiFormLogic.invalidCounter.condition ? styles.itemBorderBottomNone : null,
                )}
              >
                <div
                  className={classNames(styles.counterItem, styles.itemPaddingTop, {
                    [styles.itemPaddingVertical]:
                      multiFormLogic.followCheckbox.get && !multiFormLogic.counterCheckbox.get,
                  })}
                >
                  <div className={styles.label}>
                    {multiFormLogic.counterCheckbox.label}
                    <HelpHalfModal
                      className={styles.help}
                      content={multiFormLogic.counterCheckbox.tooltipMessage}
                      logicName={multiFormLogic.counterCheckbox.label}
                      logicImageSrc={infoImagesHelper[multiFormLogic.counterCheckbox.descriptionImage]}
                    />
                  </div>
                  <Switch
                    checked={multiFormLogic.counterCheckbox.get}
                    onChange={multiFormLogic.counterCheckbox.set}
                    disabled={multiFormLogic.counterCheckbox.isDisabled}
                  />
                </div>
                {multiFormLogic.counterCheckbox.get && (
                  <div className={classNames(styles.counterItem, styles.counterRight, styles.itemPaddingTop)}>
                    <div className={styles.label}>
                      {multiFormLogic.counterInput.label}
                      <HelpHalfModal
                        className={styles.help}
                        content={multiFormLogic.counterInput.tooltipMessage}
                        logicName={multiFormLogic.counterCheckbox.label + (isFX ? `(pips)` : '')}
                        logicImageSrc={infoImagesHelper[multiFormLogic.counterInput.descriptionImage]}
                      />
                    </div>
                    <InputNumber
                      className={classNames(styles.numberInput, styles.counter)}
                      width={96}
                      value={multiFormLogic.counterInput.get}
                      onChange={multiFormLogic.counterInput.set}
                      disabled={multiFormLogic.counterInput.isDisabled}
                      step={multiFormLogic.counterInput.step}
                      min={multiFormLogic.counterInput.min}
                      name={multiFormLogic.counterInput.name}
                      errorMessages={multiFormLogic.errorMessages}
                      validateNegativeValues
                      withErrorTooltip
                      inputStyle={styles.numberInputFont}
                      buttonStyle={styles.numberInputButton}
                    />
                  </div>
                )}
                {multiFormLogic.counterCheckbox.get && (
                  <div className={classNames(styles.counterItem, styles.counterRight)}>
                    <div className={styles.label}>
                      固定する
                      <HelpHalfModal
                        className={styles.help}
                        content={multiFormLogic.counterFixedCheckbox.tooltipMessage}
                        logicName={multiFormLogic.counterCheckbox.label + (isFX ? `(pips)` : '')}
                        logicImageSrc={infoImagesHelper[multiFormLogic.counterFixedCheckbox.descriptionImage]}
                      />
                    </div>
                    <Switch
                      checked={multiFormLogic.counterFixedCheckbox.get}
                      onChange={multiFormLogic.counterFixedCheckbox.set}
                      disabled={multiFormLogic.counterFixedCheckbox.isDisabled}
                    />
                  </div>
                )}
              </div>
              {multiFormLogic.invalidCounter.condition && (
                <div className={classNames(styles.item)}>
                  <div className={classNames(styles.counterItem, styles.validationErrorMessage)}>
                    {multiFormLogic.invalidCounter.message}
                  </div>
                </div>
              )}
              <div
                className={classNames(
                  styles.item,
                  multiFormLogic.followCheckbox.get ? styles.itemBorderBottomNone : null,
                  multiFormLogic.invalidCounter.condition ? styles.itemBorderBottomNone : null,
                )}
              >
                <div className={styles.label}>
                  {multiFormLogic.followCheckbox.label}
                  <HelpHalfModal
                    className={styles.help}
                    content={multiFormLogic.followCheckbox.tooltipMessage}
                    logicName={multiFormLogic.followCheckbox.label}
                    logicImageSrc={infoImagesHelper[multiFormLogic.followCheckbox.descriptionImage]}
                  />
                </div>
                <Switch
                  checked={multiFormLogic.followCheckbox.get}
                  onChange={multiFormLogic.followCheckbox.set}
                  disabled={multiFormLogic.followCheckbox.isDisabled}
                />
              </div>
              {multiFormLogic.invalidCounter.condition && (
                <div className={classNames(styles.item, styles.itemBorderBottomNone, styles.itemPaddingBottom)}>
                  <div className={classNames(styles.counterItem, styles.validationErrorMessage)}>
                    {multiFormLogic.invalidCounter.message}
                  </div>
                </div>
              )}
              {multiFormLogic.followCheckbox.get && (
                <div className={classNames(styles.item, styles.right, styles.itemPaddingBottom)}>
                  <InputNumber
                    className={styles.numberInput}
                    width={shortNumberInputWidth}
                    value={multiFormLogic.followInput.get}
                    onChange={multiFormLogic.followInput.set}
                    disabled={multiFormLogic.followInput.isDisabled}
                    min={multiFormLogic.followInput.min}
                    name={multiFormLogic.followInput.name}
                    errorMessages={multiFormLogic.errorMessages}
                    validateNegativeValues
                    withErrorTooltip
                    inputStyle={styles.numberInputFont}
                    buttonStyle={styles.numberInputButton}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>

      <div className={styles.footer}>
        <div className={styles.buttonGroup}>
          <Button onClick={multiFormLogic.submit.handler} disabled={multiFormLogic.submit.isDisabled}>
            {multiFormLogic.submit.label}
          </Button>
        </div>
      </div>
    </div>
  );
});

SideMenu.propTypes = {
  setIssueIsHovered: PropTypes.func.isRequired,
};
