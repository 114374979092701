export const CART_TABS = {
  CURRENT: {
    ID: 0,
    NAME: '現在のカート',
  },
  HISTORY: {
    ID: 1,
    NAME: '注文履歴',
  },
  SAVED: {
    ID: 2,
    NAME: '保存済み',
  },
};

export const TABLE_DEBOUNCE_VALUE = 300;

export const MIN_STRATEGY_SETS = 1;
export const CART_TITLE = 'カート';
export const SAVE_BUTTON = '保存';
export const CART_EMPTY_CHART_MESSAGE = 'カートに自動売買を追加した場合、シミュレーションが表示されます';
export const CURRENT_CART_EMPTY_TEXT = 'カートに追加されている自動売買はありません';
export const CART_CONFIRMATION_BUTTON = '注文を確定する';
export const CURRENT_CART_SUBMIT_BUTTON = '注文設定を確認する';
export const TOTAL_REQUIRED_MARGIN = '発注証拠金目安合計';
export const TOTAL_RECOMMENDED_MARGIN = '推奨証拠金合計';
export const CART_TABLE_TITLE = 'カート内の注文設定';
export const ADD_TO_CART_BUTTON = 'カートに追加';
export const EDIT_BUTTON = '変更';
export const DELETE_BUTTON = '削除';
export const CANCEL_BUTTON = 'キャンセル';
export const QUANTITY_INPUT_LABEL = 'セット数';
export const SAVED_CART_EMPTY_MESSAGE = '保存されたカートはありません';
