import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import Modal from '../../../../components/Modal';
import styles from './builderWarningInfo.module.scss';
import helpSimulation from '../../../../assets/builder/help_simulation.png';

const BuilderWarningInfo = ({ isOpen, closeModal }) => (
  <Modal size={MODAL_SIZES.WIDTH_710} isOpen={isOpen} closeModal={closeModal} title="シミュレーション画面の見方">
    <div className={styles.bodyWrapper}>
      <div className={styles.content}>
        <div className={styles.grayBox}>
          表示画像は参考イメージです。ルールの設定値や選択する期間によって、実装の表示内容は異なります。
        </div>
      </div>

      <div className={styles.content}>
        <img src={helpSimulation} width="650" height="300" alt="バックテストシミュレーション画像" />
      </div>

      <div className={styles.content}>
        <div className={styles.text}>選択した期間におけるバックテストシミュレーション結果を表示しています。</div>
      </div>

      <div className={styles.border} />

      <div className={styles.content}>
        <div>
          <div className={styles.title}>損益チャート</div>
          <div className={styles.subTextSpace}>
            <div>●緑線 実現損益</div>
            <div>実現損益の合計を表示します。</div>
            <div>●赤線 評価損益</div>
            <div>建玉の未実現損益を表示します。</div>
            <div>●白線 総合損益</div>
            <div>実現損益＋評価損益の合計です。</div>
          </div>
        </div>
      </div>

      <div className={styles.border} />

      <div className={styles.content}>
        <div>
          <div className={styles.title}>シミュレーション結果</div>
          <div className={styles.subTextSpace}>
            <div>●総合損益</div>
            <div>選択期間内における前営業日までの実現損益＋評価損益の合計です。</div>
            <div>●収益率</div>
            <div>選択期間内の推奨証拠金に対する総合損益の割合です。</div>
            <div>●総合評価</div>
            <div>
              シミュレーションにおける最大ドローダウン、収益率、取引頻度、レンジ位置を総合的に評価し、
              5段階評価で表しています。評価の数字が大きいほどルールの総合力が高いと考えられます。
            </div>
            <div>●発注証拠金目安</div>
            <div>発注するために必要な最低証拠金です。</div>
            <div>●推奨証拠金</div>
            <div>相場変動リスクを考慮した運用資金の目安です。</div>
            <div>●新規</div>
            <div>選択期間内に約定した新規注文回数です。</div>
            <div>●決済</div>
            <div>選択期間内に約定した決済注文回数です。</div>
          </div>
        </div>
      </div>

      <div className={styles.border} />

      <div className={styles.content}>
        <div>
          <div className={styles.title}>注意事項</div>
          <div className={styles.subTextSpace}>
            <div>
              シミュレーション画面は、過去のFX・CFD・ETFの1分足レートをもとにスプレッド、
              スワップポイント(FX)、金利・配当相当額(CFD)、
              金利・分配相当額(ETF)を加味した数値でバックテストを行った結果を表示しています。
            </div>
            <div>
              将来の結果を保証するものではありませんのでご注意ください。また、バックテストは実際の取引と異なります。
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

BuilderWarningInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(BuilderWarningInfo);
