import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { OPTIONS_ASK_BID } from 'shared-modules/constants';
import { GroupButton } from '../GroupButton';
import styles from './askBidGroupButton.module.scss';

const [BID_OPTION, ASK_OPTION] = OPTIONS_ASK_BID;

export const AskBidGroupButton = memo(({ buySell, isNotSelectable, disabledBuy, disabledSell, onChange }) => {
  const buttons = useMemo(
    () => [
      {
        ...BID_OPTION,
        isDisabled: disabledSell,
        defaultClassName: styles.bidAskButton,
        selectedClassName: styles.bid,
      },
      {
        ...ASK_OPTION,
        isDisabled: disabledBuy,
        defaultClassName: styles.bidAskButton,
        selectedClassName: styles.ask,
      },
    ],
    [disabledBuy, disabledSell],
  );
  return (
    <GroupButton
      buttons={buttons}
      activeId={buySell}
      showLockIcon={disabledBuy || disabledSell}
      disabled={isNotSelectable}
      onChange={onChange}
    />
  );
});

AskBidGroupButton.propTypes = {
  buySell: PropTypes.number.isRequired,
  disabledBuy: PropTypes.bool,
  disabledSell: PropTypes.bool,
  isNotSelectable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

AskBidGroupButton.defaultProps = {
  disabledBuy: false,
  disabledSell: false,
  isNotSelectable: false,
};
