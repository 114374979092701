import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { EditIconButton } from '../../../../../components/EditIconButton';

export const EditIcon = memo(({ id, onClick }) => {
  const simulationDataIsLoading = useSelector((state) => state.builder.simulationDataIsLoading);

  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return <EditIconButton iconName="edit" onClick={handleClick} disabled={simulationDataIsLoading} />;
});

EditIcon.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
