import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  COMPREHENSIVE_EVALUATION_LABEL,
  RECOMMENDED_MARGIN_LABEL,
  REQUIRED_MARGIN_LABEL,
} from 'shared-modules/constants';
import { BUILDER_ORDER_TYPES } from 'shared-modules/constants/builder';
import { roundRoi, roundUpBy1000 } from 'shared-modules/utils';
import { Evaluation } from '../../../../../../../components';
import { ColoredPriceText, ColoredRateText, MarginText, NumberText } from '../../../../../../../components/Texts';
import styles from '../../../../../../../components/SimulationInfo/simulationInfo.module.scss';

// ta-frontend-main-lotus/web-app/src/components/SimulationInfo/index.jsをコピーして作成
// セレクト&テクニカルビルダーとビルダーで項目名が異なるため、別コンポーネントとして作成
// TODO: セレクト、テクニカルビルダーのUI修正時にコンポーネントを統一する
export const BuilderSimulationInfo = memo(
  ({
    totalPl,
    marginRecommended,
    marginRequired,
    newCount,
    closeCount,
    comprehensiveEvaluation,
    marginRecommendedLabel,
  }) => {
    const roundedMarginRecommended = roundUpBy1000(marginRecommended);
    const calculatedRoi = useMemo(
      () => roundRoi((totalPl * 100) / roundedMarginRecommended),
      [totalPl, roundedMarginRecommended],
    );

    const orderType = useSelector((state) => state.builder.orderType);

    return (
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.label}>総合損益</div>
          <ColoredPriceText value={totalPl} className={styles.value} symbolClassName={styles.symbol} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>収益率</div>
          <ColoredRateText value={calculatedRoi} className={styles.value} symbolClassName={styles.symbol} />
        </div>
        {orderType !== BUILDER_ORDER_TYPES.TECH.ID && (
          <div className={styles.row}>
            <div className={styles.label}>{COMPREHENSIVE_EVALUATION_LABEL}</div>
            <Evaluation
              scoreClassName={styles.evaluationScore}
              iconClassName={styles.evaluationIcon}
              value={comprehensiveEvaluation}
            />
          </div>
        )}
        <div className={styles.row}>
          <div className={styles.label}>{REQUIRED_MARGIN_LABEL}</div>
          <MarginText value={marginRequired} className={styles.value} symbolClassName={styles.symbol} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>{marginRecommendedLabel || RECOMMENDED_MARGIN_LABEL}</div>
          <MarginText value={roundedMarginRecommended} className={styles.value} symbolClassName={styles.symbol} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>新規</div>
          <NumberText value={newCount} symbol="回" className={styles.value} symbolClassName={styles.symbol} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>決済</div>
          <NumberText value={closeCount} symbol="回" className={styles.value} symbolClassName={styles.symbol} />
        </div>
      </div>
    );
  },
);

BuilderSimulationInfo.propTypes = {
  totalPl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRecommended: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRequired: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  newCount: PropTypes.number,
  closeCount: PropTypes.number,
  comprehensiveEvaluation: PropTypes.number,
  marginRecommendedLabel: PropTypes.string,
};

BuilderSimulationInfo.defaultProps = {
  totalPl: undefined,
  marginRecommended: undefined,
  marginRequired: undefined,
  newCount: undefined,
  closeCount: undefined,
  comprehensiveEvaluation: undefined,
  marginRecommendedLabel: undefined,
};
