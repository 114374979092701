/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getServiceQuantityUnit, toCommaSeparatedNumber } from 'shared-modules/services/index';
import { getCurrencyUnitByServiceId } from 'shared-modules/utils';
import { CFD, FX } from 'shared-modules/constants';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import { Table } from '../../../components';
import BuySellItem from '../../../components/BuySellItem';
import { DeleteIcon } from '../../Builder/components/ConfigurationPage/ChartSettings/DeleteIcon';
import styles from './chartMakeOrderSettingTable.module.scss';

const settingsResized = {
  key: 'chartMakeFxSettingsTable',
  default: [82, 107, 70, 70, 82, 82, 70, 82, 82, 82, 70, 97],
};

const ChartMakeOrderSettingTable = ({ isLoading, orderSettingsData, deleteHandler, instrumentId }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);

  const quantityUnit = getServiceQuantityUnit(serviceId);

  const tableMetaInfo = useMemo(
    () => ({
      loading: isLoading,
      pageNumber: 1,
      totalPages: 1,
    }),
    [isLoading],
  );

  const Cell = ({ cell: { value } }) => (value ? toCommaSeparatedNumber(value) : '-');

  const labelUnit = useMemo(
    () => (serviceId === CFD ? '' : `(${getCurrencyUnitByServiceId(serviceId, instrumentId)})`),
    [serviceId, instrumentId],
  );

  const settingsColumns = useMemo(
    () => [
      {
        Header: '番号',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ row: { index } }) => index + 1,
      },
      {
        Header: '銘柄',
        accessor: 'instrumentId',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (serviceId === FX ? removeSuffix(value) : value),
      },
      {
        Header: '売買',
        accessor: 'side',
        Cell: ({ cell: { value } }) => (value ? <BuySellItem type={value} /> : '-'),
        disableSortBy: true,
      },
      {
        Header: `数量(${quantityUnit})`,
        accessor: 'quantity',
        isNumber: true,
        disableSortBy: true,
        Cell,
      },
      {
        Header: 'エントリー価格1',
        accessor: 'entryPrice1',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: 'エントリー価格2',
        accessor: 'entryPrice2',
        isNumber: true,
        Cell,
        disableSortBy: true,
      },
      {
        Header: `利確幅${labelUnit}`,
        accessor: 'tp',
        isNumber: true,
        Cell,
        disableSortBy: true,
      },
      {
        Header: `損切幅${labelUnit}`,
        accessor: 'sl',
        isNumber: true,
        Cell,
        disableSortBy: true,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        isNumber: true,
        Cell,
        disableSortBy: true,
      },
      {
        Header: 'カウンター値(価格指定)',
        // can't pass in multi accessor values, so use function -> we can customize what to display simply
        accessor: ({ counterPrice, counter }) => (counterPrice || counterPrice === 0 ? `(${counterPrice})` : counter),
        Cell,
        isNumber: true,
        disableSortBy: true,
        minWidth: 90,
      },
      {
        Header: '',
        accessor: 'renderButtons',
        Cell: ({
          row: {
            original: { id },
          },
        }) => allowOrderEdit && <DeleteIcon id={id.toString()} onClick={deleteHandler} />,
        disableSortBy: true,
        sticky: 'right',
        minWidth: 120,
      },
    ],
    [quantityUnit, labelUnit, serviceId, deleteHandler, allowOrderEdit],
  );

  return (
    <>
      <p style={{ fontWeight: 600, fontSize: 14, margin: '10px 20px' }}>注文設定</p>
      <Table
        tableData={orderSettingsData}
        columns={settingsColumns}
        resized={settingsResized}
        className={styles.table}
        tableMetaInfo={tableMetaInfo}
        key={1}
      />
      <div className={styles.divider} />
    </>
  );
};

ChartMakeOrderSettingTable.propTypes = {
  orderSettingsData: PropTypes.arrayOf(
    PropTypes.shape({
      side: PropTypes.number.isRequired,
      instrumentId: PropTypes.string.isRequired,
      entryPrice1: PropTypes.number.isRequired,
      entryPrice2: PropTypes.number,
      quantity: PropTypes.number.isRequired,
      tp: PropTypes.number.isRequired,
      sl: PropTypes.number,
      follow: PropTypes.number,
      counter: PropTypes.number,
      counterPrice: PropTypes.number,
    }),
  ),
  instrumentId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  deleteHandler: PropTypes.func,
};
ChartMakeOrderSettingTable.defaultProps = {
  orderSettingsData: [],
  isLoading: false,
  deleteHandler: () => {},
};

export default memo(ChartMakeOrderSettingTable);
